import { Injectable, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalManager, Spinner, ngbDateStructToString } from "@concurrency/angular";
import { Util } from "@concurrency/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {
    Workbook,
    WorkbookSheet,
    WorkbookSheetRowCell
} from "@progress/kendo-ooxml";
import { BehaviorSubject } from "rxjs";
import { CommonDialogInfoComponent } from "src/app/_add-in/common/dialog/common-dialog-info.component";
import { FieldType } from "src/app/_add-in/data/enums/field-type";
import { InputName } from "src/app/_add-in/data/enums/input-name";
import { FieldData } from "src/app/_add-in/data/field-data";
import { FieldService } from "src/app/_add-in/data/field.service";
import { InputBuilder } from "src/app/_add-in/data/input.builder";
import * as dt from "src/app/_add-in/dialog/dialog-text";
import { ExcelDialogType } from "src/app/_add-in/excel/excel-dialog-type";
import { ExcelDialogTypesBuilder } from "src/app/_add-in/excel/excel-dialog-types.builder";
import { ExcelEstimate } from "src/app/_add-in/excel/excel.estimate";
import * as help from "src/app/_add-in/help/help-text";
import { Menu } from "src/app/_add-in/menu/menu";
import { SummaryTableHeaderService } from "src/app/_add-in/summary/summary-table-header.service";
import { CountryClient } from "src/app/_api/clients/country.client";
import { IntlBetaClient } from "src/app/_api/clients/intlBeta.client";
import { IntlErpClient } from "src/app/_api/clients/intlErp.client";
import { IntlIndustryClient } from "src/app/_api/clients/intlIndustry.client";
import { IntlRfClient } from "src/app/_api/clients/intlRf.client";
import { USIndustryClient } from "src/app/_api/clients/usindustry.client";
import { Country } from "src/app/_api/responses/country.response";
import { Gic } from "src/app/_api/responses/gic.response";
import { Industry } from "src/app/_api/responses/industry.response";
import { IntlData } from "src/app/_api/responses/intl-data.response";
import { IndustryListNoDetailsSelectedComponent } from "src/app/benchmarking/modals/industry-list-no-details-selected.component";
import { environment } from "src/environments/environment";
import { CommonDate, DateStruct } from "../common/date-struct";
import { ValueType } from "../data/enums/value-type";
import { SuggestedInput } from "../data/suggested-input.model";
import { User } from "../user/model/user.model";
@Injectable()
export class    ExcelStorageService {
    constructor(
        private zone: NgZone,
        private tableHeaderService: SummaryTableHeaderService,
        private fieldService: FieldService,
        private industryService: USIndustryClient,
        public inputBuilder: InputBuilder,
        private dialog: MatDialog,
        private excelDialogTypesBuilder: ExcelDialogTypesBuilder,
        public modalManager: ModalManager,
        private spinner: Spinner,
        public intlErpClients: IntlErpClient,
        public intlRfClients: IntlRfClient,
        public intlIndustries: IntlIndustryClient,
        public intlBetas: IntlBetaClient,
        public intlCountries: CountryClient
        // public intlCOCSheetService: IntlCOCSheetService


    ) {
        Office.context.document.addHandlerAsync(
            Office.EventType.DocumentSelectionChanged,
            this.worksheetNameChanged.bind(this)
        );
    }

    private _excelEstimate = new BehaviorSubject<ExcelEstimate | undefined>(
        undefined
    );
    public ExcelEstimate = this._excelEstimate.asObservable();
    private _worksheetName = "";
    // private _dataSummaryWorksheetId = '';
    public dateforcal: { day: number; month: number; year: number } =
        {} as NgbDateStruct;
    public previousdate1: { day: number; month: number; year: number } =
        {} as NgbDateStruct;
    public previousdate2: { day: number; month: number; year: number } =
        {} as NgbDateStruct;
    public previousdate3: { day: number; month: number; year: number } =
        {} as NgbDateStruct;
    public previousdate4: { day: number; month: number; year: number } =
        {} as NgbDateStruct;
    private excelPosition: number = 0;
    public investeeCountries!: string;
    public investeeCountriesList!: string[];
    public investeeCountryCount: number = 0;
    private _isDynamicDataSummary = false;
    private _isOfflineValuesLoaded = false;
    public loopcount = 0;
    public userprofile!: User;
    public isAlreadyBinded = false;
    public isIndustryDisplay: boolean = false;
    public modelValueupdated = false;
    public isNotAvailabledialogShowen = false;
    public intlIndustryOptions: Gic[] = [];
    public notAvailableDialogRef: any;
    public isNotSelecteddialogShowen = false;
    public notSelectedDialogRef: any;
    public lstIndustries: Gic[] = [];
    public listIntlBetas: IntlData[] = [];
    public lstSelectedIndustries: Gic[] = [];
    public lstFinalSelectedIndustry: Gic[] = [];
    public tempIndustries: Gic[] = [];
    public maximumNumberOfIndustries = 1;
    public searchValue: any;
    public isAddClicked: any;
    private _check: string = '';
    public investorCountriesComboOptions: SuggestedInput[] = [];
    public investorCountries: Country[] = [];
    public investorCurrencyComboOptions: SuggestedInput[] = [];
    public intlIndustryComboOptions: SuggestedInput[] = [];
    public listIntlErpClients: IntlData[] = [];
    public listIntlRfClients: IntlData[] = [];
    public isIndustryNotAvailable = false;
    public preserveIntlValuationDate: DateStruct | undefined;
    public erpDropdownLabels: string[] = [];
    public rfRateDropdownLabels: string[] = [];
    public betaDropdownLabels: string[] = [];
    public ddnlabel: string = '<<<select from dropdown or type in custom value. Leave blank if you are not using the KrollWACCTemplate exported from the excel add-in.';
    public isRefreshDataEnable = false;
    public isExportTemplateEnable = false;
    public intlValuationDateExists: DateStruct | undefined;
    public intlInvestorCountryEXists: any;
    public intlInvestorCurrencyExists: any;
    public cocResourceCenterUrl: string = 'https://www.kroll.com/cost-of-capital-resource-center';

    public static SerialDateToJSDate(excelSerialDate: number): Date {
        return new Date(Date.UTC(0, 0, excelSerialDate - 1));
    }

    private updateModel(model: FieldData): void {
        if (model.value !== "") {
            this.fieldService.updateModel(model, this);
        } else if (
            model.value === "" &&
            model.source === InputName.ValuationDate
        ) {
            this.fieldService.resetAllvaluestoEmpty(this);
        }
    }

    public handleExcelErrors(error: object): void {
        // TODO: handle errors coming from the Excel API by showing a message to the end user
        if (error instanceof OfficeExtension.Error) {
            console.error("Debug info: " + JSON.stringify(error.debugInfo));
        }
    }

    public setCellBinding(
        name: string,
        address: string,
        fieldType: FieldType
    ): void {
        this.zone
            .run(async () => {
                await Excel.run(async (context: Excel.RequestContext) => {
                    if (this.sheetWithSpace(address) && address && fieldType) {
                        const binding = context.workbook.bindings.add(
                            this.sheetWithSpace(address),
                            Excel.BindingType.range,
                            name
                        );
                        if (
                            fieldType === FieldType.Input ||
                            fieldType === FieldType.TypeAhead 
                        ) {
                            binding.onDataChanged.add((eventArgs) =>
                                this.inputValueChanged(eventArgs)
                            );
                        }
                        if (fieldType === FieldType.Output) {
                            binding.onDataChanged.add((eventArgs) =>
                                this.outputValueChanged(eventArgs)
                            );
                        }
                        if (fieldType === FieldType.DatePicker) {
                            binding.onDataChanged.add((eventArgs) =>
                                this.valuationDateChanged(eventArgs)
                            );
                        }
                    }

                    return await context.sync();
                }).catch(this.handleExcelErrors);
            })
            .catch((err) => {
                console.error(err);
            });
    }
    public setOutputCellBinding(
        name: string,
        address: string,
        fieldType: FieldType
    ): void {
        this.zone
            .run(async () => {
                await Excel.run(async (context: Excel.RequestContext) => {
                    if (this.sheetWithSpace(address) && address && fieldType) {
                        const binding = context.workbook.bindings.add(
                            this.sheetWithSpace(address),
                            Excel.BindingType.range,
                            name
                        );
                        binding.onDataChanged.add((eventArgs) =>
                            this.outputValueChanged(eventArgs)
                        );
                    }
                    return await context.sync();
                }).catch(this.handleExcelErrors);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    private inputValueChanged(
        eventArgs: Excel.BindingDataChangedEventArgs
    ): Promise<void> {
        return this.zone.run(async () => {
            const fieldSource = eventArgs.binding.id;
            const fields = this.getExcelEstimate();

            if (fields == null) {
                throw new Error("Excel estimate cannot be null!");
            }
            return await Excel.run(
                { delayForCellEdit: true },
                async (context: Excel.RequestContext) => {
                    const cellBinding =
                        context.workbook.bindings.getItem(fieldSource);
                    const cellRange = cellBinding.getRange();
                    cellRange.load(["address", "text", "values"]);

                    return await context.sync().then(() => {
                        const model = this.getValueFromSource(
                            fieldSource,
                            fields
                        );

                        // if no value was found return
                        if (model == null) {
                            return;
                        }
                        if (
                            model.value &&
                            model.value.GicIndustryCode != null &&
                            model.value.GicIndustryCode.toString() ===
                                cellRange.values[0][0].toString()
                        ) {
                            const formattedAddress: string =
                                this.formatCellAddress(cellRange.address);
                            model.reference = formattedAddress;
                            this.formatBoundCellRange(cellRange);
                            this.intiateUpdateDataSummary();
                            return;
                        }
                        if (
                            model.value &&
                            model.value.SicIndustryCode != null &&
                            model.value.SicIndustryCode.toString() ===
                                cellRange.values[0][0].toString()
                        ) {
                            const formattedAddress: string =
                                this.formatCellAddress(cellRange.address);
                            model.reference = formattedAddress;
                            this.formatBoundCellRange(cellRange);
                            this.intiateUpdateDataSummary();
                            return;
                        }

                        if (model.value === cellRange.values[0][0]) {
                            // the value in the add-in equals the value in the cell, check if the reference has changed

                            // when non-alpha-numeric characters are used within sheet names, Excel surrounds the name between simple quotes
                            // this breaks the add-in reference, so the simple quotes need to be removed before processing the address
                            const formattedAddress: string =
                                this.formatCellAddress(cellRange.address);
                            this.intiateUpdateDataSummary();
                            // if both references are the same return
                            if (model.reference === formattedAddress) {
                                return;
                            }

                            // update the add-in reference to the new Excel reference
                            model.reference = formattedAddress;
                            this.saveToEstimate(model);
                        } else {
                            // the value in the add-in is different from the value in the spreadsheet

                            if (cellRange.values[0][0] === "") {
                                if (model.valueType === ValueType.Industry) {
                                    model.value = "";
                                    this.fieldService.resetIndustryvaluestoEmpty(
                                        model,
                                        this
                                    );
                                    model.context = "N/A";
                                    this.modelValueupdated = true;
                                }
                                // the value in Excel is empty which means the cell was removed
                                // remove the binding (but leave the value in the add-in)
                                model.value = "";
                                this.updateModel(model);
                                this.intiateUpdateDataSummary();
                                // Removed this as we need to lock the cell though it is deleted
                                // if (model.config) {
                                //     model.config.disabled = false;
                                // }
                            } else if (
                                cellRange.values[0][0] !== "" &&
                                typeof cellRange.values[0][0] !== "number" && model.valueType !== ValueType.IntlInvestorCountry
                            ) {
                                // the value in Excel is not a number, display an error message and make the text box empty in Add-In
                                model.value = "";
                                this.updateModel(model);

                                this.displayDialog(ExcelDialogType.NaN);
                            }else {
                                const formattedAddress: string =
                                    this.formatCellAddress(cellRange.address);
                                // this.intiateUpdateDataSummary();
                                if (model.reference !== formattedAddress) {
                                    model.reference = formattedAddress;
                                    this.saveToEstimate(model);
                                }

                                let industryExists = false;
                                if (
                                    model.value &&
                                    typeof model.value === "object" &&
                                    model.valueType === ValueType.Industry
                                ) {
                                    if (
                                        model.value.GicIndustryCode !== null &&
                                        cellRange.values[0][0].toString() ===
                                            model.value.GicIndustryCode.toString()
                                    ) {
                                        industryExists = true;
                                    } else if (
                                        model.value.SicIndustryCode !== null &&
                                        cellRange.values[0][0].toString() ===
                                            model.value.SicIndustryCode.toString()
                                    ) {
                                        industryExists = true;
                                    }
                                }
                                model.value = cellRange.values[0][0];
                                if (
                                    model.valueType === ValueType.Industry &&
                                    typeof model.value === "number"
                                ) {
                                    let date = ngbDateStructToString(
                                        this.inputBuilder.valuationDate.value
                                    );
                                    if (date && date.includes("T")) {
                                        date = date.split("T")[0];
                                    }
                                    if (
                                        model.context !== "N/A" ||
                                        typeof model.value !== "string"
                                    ) {
                                        if (
                                            this.inputBuilder.valuationDate
                                                .value &&
                                            this.inputBuilder.intlIndustries
                                                .value.length == 0
                                        ) {
                                            let valudate =
                                                ngbDateStructToString(
                                                    this.inputBuilder
                                                        .valuationDate.value
                                                );
                                            const modelValue = model.value;
                                            const request =
                                                this.industryService.read(
                                                    valudate
                                                );
                                            request.subscribe(
                                                (param: Industry[]) => {
                                                    this.inputBuilder.intlIndustries.value =
                                                        param;
                                                    model.value =
                                                        this.getIndustry(
                                                            this.inputBuilder
                                                                .intlIndustries
                                                                .value,
                                                            date,
                                                            modelValue.toString(),
                                                            model
                                                        );
                                                    if (model.value) {
                                                        this.fieldService.updateIndustry(
                                                            model,
                                                            this,
                                                            industryExists
                                                        );
                                                        this.intiateUpdateDataSummary();
                                                        return;
                                                    } else {
                                                        this.fieldService.resetIndustryvaluestoEmpty(
                                                            model,
                                                            this
                                                        );
                                                        model.value =
                                                            typeof model.value ===
                                                            "string"
                                                                ? model.value
                                                                : "N/A";
                                                        model.context = "N/A";
                                                        this.intiateUpdateDataSummary();
                                                        return;
                                                    }
                                                }
                                            );
                                        } else {
                                            model.value = this.getIndustry(
                                                this.inputBuilder.intlIndustries
                                                    .value,
                                                date,
                                                model.value.toString(),
                                                model
                                            );
                                            if (model.value) {
                                                this.fieldService.updateIndustry(
                                                    model,
                                                    this,
                                                    industryExists
                                                );
                                                this.intiateUpdateDataSummary();
                                                return;
                                            } else {
                                                this.fieldService.resetIndustryvaluestoEmpty(
                                                    model,
                                                    this
                                                );
                                                model.value =
                                                    typeof model.value ===
                                                    "string"
                                                        ? model.value
                                                        : "N/A";
                                                model.context = "N/A";
                                                this.intiateUpdateDataSummary();
                                                return;
                                            }
                                        }

                                        // model.value = this.getIndustry(this.inputBuilder.intlIndustries.value, date, model.value.toString(), model);
                                    }
                                } else {
                                    this.updateModel(model);
                                    if(model.valueType !== ValueType.IntlInvestorCountry){
                                    this.intiateUpdateDataSummary();
                                    }
                                }
                            }
                        }
                    });
                }
            ).catch((err) => {
                if (err.code === Excel.ErrorCodes.invalidBinding) {
                    Office.context.document.bindings.releaseByIdAsync(
                        fieldSource,
                        () => {
                            const unboundValue = this.getValueFromSource(
                                fieldSource,
                                fields
                            );
                            if (unboundValue == null) {
                                return;
                            }
                            if (unboundValue.config) {
                                unboundValue.config.disabled = false;
                            }
                            this.removeBindingFromEstimate(unboundValue);
                        }
                    );
                }
            });
        });
    }
    public getRangeForIndustries(model: FieldData): Promise<void> {
        return this.zone.run(async () => {
            return await Excel.run(
                { delayForCellEdit: true },
                async (context: Excel.RequestContext) => {
                    const cellBinding = context.workbook.bindings.getItem(
                        model.source
                    );
                    const cellRange = cellBinding.getRange();
                    cellRange.load(["address", "text", "values"]);
                    model.value = cellRange.values[0][0].tostring();
                }
            );
        });
    }

    private valuationDateChanged(
        eventArgs: Excel.BindingDataChangedEventArgs
    ): Promise<void> {
        return this.zone.run(async () => {
            const fieldSource = eventArgs.binding.id;
            const fields = this.getExcelEstimate();

            if (fields == null) {
                throw new Error("Excel estimate cannot be null!");
            }
            return await Excel.run(
                { delayForCellEdit: true },
                async (context: Excel.RequestContext) => {
                    const cellBinding =
                        context.workbook.bindings.getItem(fieldSource);
                    const cellRange = cellBinding.getRange();
                    cellRange.load(["address", "text", "values"]);

                    // if(this.inputBuilder.industry0.value==='N/A'){
                    //     const cellBinding = context.workbook.bindings.getItem('industry0');
                    //     const range = cellBinding.getRange();
                    //     range.load(['address', 'text', 'values']);
                    //     this.inputBuilder.industry0.value=range.values[0][0].toString();
                    // }

                    return await context.sync().then(() => {
                        const model = this.getValueFromSource(
                            fieldSource,
                            fields
                        );

                        // if no value was found return
                        if (model == null) {
                            return;
                        }
                        // let display = false;
                        if (
                            model.value !== null &&
                            cellRange.values[0][0] !== ""
                        ) {
                            const datecode = cellRange.values[0][0];
                            const date =
                                ExcelStorageService.SerialDateToJSDate(
                                    datecode
                                );
                            this.dateforcal = {
                                day: date.getUTCDate(),
                                month: date.getUTCMonth() + 1,
                                year: date.getUTCFullYear(),
                            };
                            cellRange.values[0][0] = this.dateforcal;
                        }
                        if (
                            JSON.stringify(model.value) ===
                            JSON.stringify(cellRange.values[0][0])
                        ) {
                            // the value in the add-in equals the value in the cell, check if the reference has changed

                            // when non-alpha-numeric characters are used within sheet names, Excel surrounds the name between simple quotes
                            // this breaks the add-in reference, so the simple quotes need to be removed before processing the address
                            const formattedAddress: string =
                                this.formatCellAddress(cellRange.address);
                            // if both references are the same return

                            if (model.reference === formattedAddress) {
                                return;
                            }

                            // update the add-in reference to the new Excel reference
                            model.reference = formattedAddress;
                            this.intiateUpdateDataSummary();
                            this.saveToEstimate(model);
                        } else {
                            // the value in the add-in is different from the value in the spreadsheet

                            if (cellRange.values[0][0] === "") {
                                // the value in Excel is empty which means the cell was removed
                                // remove the binding (but leave the value in the add-in)
                                model.value = "";
                                this.fieldService.resetAllvaluestoEmpty(this);
                                // this.updateValue(model);
                                this.intiateUpdateDataSummary();
                                this.saveToEstimate(model);
                                // Removed this as we need to lock the cell though it is deleted
                                // if (model.config) {
                                //     model.config.disabled = true;
                                // }
                            } else {
                                // the value in Excel is not empty and is a number, update the value in the add-in
                                model.value = cellRange.values[0][0];
                                this.updateModel(model);
                                this.intiateUpdateDataSummary();
                            }
                        }
                    });
                }
            ).catch((err) => {
                if (err.code === Excel.ErrorCodes.invalidBinding) {
                    Office.context.document.bindings.releaseByIdAsync(
                        fieldSource,
                        () => {
                            const unboundValue = this.getValueFromSource(
                                fieldSource,
                                fields
                            );
                            if (unboundValue == null) {
                                return;
                            }
                            if (unboundValue.config) {
                                unboundValue.config.disabled = false;
                            }
                            this.removeBindingFromEstimate(unboundValue);
                        }
                    );
                }
            });
        });
    }

    private deleteValue(model: FieldData, binding: Excel.Binding): void {
        this.removeBindingFromEstimate(model);
        const range = binding.getRange();

        // for an input, we only clear the cell formatting but leave the value in the cell
        if (
            model.fieldType === FieldType.Input ||
            model.fieldType === FieldType.DatePicker ||
            model.fieldType === FieldType.TypeAhead ||
            model.fieldType === FieldType.IntlCombobox
        ) {
            this.clearCellFormatting(range);
        }
        // for an output, we clear the cell completely (including the value)
        if (model.fieldType === FieldType.Output) {
            range.clear();
        }

        binding.delete();
    }

    private outputValueChanged(
        eventArgs: Excel.BindingDataChangedEventArgs
    ): Promise<void> {
        return this.zone.run(async () => {
            const fieldSource = eventArgs.binding.id;
            const fields = this.getExcelEstimate();

            if (fields == null) {
                return;
            }

            return await Excel.run(
                { delayForCellEdit: true },
                async (context: Excel.RequestContext) => {
                    const cellBinding =
                        context.workbook.bindings.getItem(fieldSource);
                    const myRange = cellBinding.getRange();
                    myRange.load(["address", "text", "values"]);

                    return await context.sync().then(() => {
                        const model = this.getValueFromSource(
                            fieldSource,
                            fields
                        );

                        if (model == null || model.value == null) {
                            return;
                        }

                        // if the model has a decimal value, use it to better handle Excel built-in formatting options
                        const valueUsed = model.decimalValue
                            ? model.decimalValue
                            : model.value;

                        if (
                            valueUsed === myRange.values[0][0] ||
                            (valueUsed === "0.00%" &&
                                myRange.values[0][0] === 0)
                        ) {
                            // the value in the add-in equals the value in the cell, check if the reference has changed
                            const formattedAddress: string =
                                this.formatCellAddress(myRange.address);
                            this.intiateUpdateDataSummary();
                            // if both references are the same return
                            if (model.reference === formattedAddress) {
                                return;
                            }

                            // update the add-in reference to the new Excel reference
                            model.reference = formattedAddress;
                            this.saveToEstimate(model);
                        } else {
                            // the value in the add-in is different from the value in the spreadsheet

                            if (myRange.text[0].toString() === "") {
                                // the value in Excel is empty which means that the cell was removed, remove the value from the add-in
                                // this.deleteValue(model, cellBinding);
                                this.intiateUpdateDataSummary();
                            } else {
                                // the value in Excel has been changed, so reset it
                                myRange.values = [[valueUsed]];
                                if (!myRange.text[0][0].includes("N/A")) {
                                    // this is to avoid an edge case when the zScore is not distressed for the HFR
                                    this.displayDialog(
                                        ExcelDialogType.ValueChanged
                                    );
                                }
                            }
                        }
                    });
                }
            ).catch((err) => {
                if (err.code === Excel.ErrorCodes.invalidBinding) {
                    Office.context.document.bindings.releaseByIdAsync(
                        fieldSource,
                        () => {
                            const unboundValue = this.getValueFromSource(
                                fieldSource,
                                fields
                            );
                            if (unboundValue == null) {
                                return;
                            }
                            this.removeBindingFromEstimate(unboundValue);
                        }
                    );
                }
            });
        });
    }

    private removeBindingFromEstimate(model: FieldData): void {
        model.reference = "";
        model.worksheetId = "";
        this.saveToEstimate(model);
    }

    public getValueFromSource(
        source: string,
        estimate: ExcelEstimate
    ): FieldData | null {
        const valueKey = Object.keys(estimate).find(
            (x) => estimate[x].source === source
        );

        if (valueKey == null) {
            return null;
        }

        return estimate[valueKey];
    }

    private getValueFromAddress(
        address: string,
        worksheetId: string,
        estimate: ExcelEstimate,
        model: FieldData
    ): FieldData | null {
        const valueKey = Object.keys(estimate).find(
            (x) =>
                estimate[x].reference === address &&
                estimate[x].worksheetId === worksheetId
        );
        if (valueKey == null) {
            return null;
        }
        // Disabling the cell if addein is already binded to any cell in the excel
        if (valueKey === model.source || model.reference) {
            this.isAlreadyBinded = true;
        }

        return estimate[valueKey];
    }

    private worksheetNameChanged(): void {
        Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                const activeWorksheet =
                    context.workbook.worksheets.getActiveWorksheet();
                activeWorksheet.load(["id", "name"]);

                const fields = this.getExcelEstimate();
                if (fields == null) {
                    return;
                }

                return await context.sync().then(() => {
                    // if both names are the same return
                    if (this._worksheetName === activeWorksheet.name) {
                        return;
                    }

                    // updates the references to the new name of the worksheet
                    this._worksheetName = activeWorksheet.name;
                    Object.keys(fields).forEach((field) => {
                        const fieldValue: FieldData = fields[field];
                        if (
                            fieldValue.reference &&
                            fieldValue.worksheetId === activeWorksheet.id
                        ) {
                            fieldValue.reference = `${
                                this._worksheetName
                            }!${this.getCellName(fieldValue.reference)}`;
                            this.zone.run(() => {
                                if (
                                    fieldValue.reference &&
                                    fieldValue.fieldType ===
                                        FieldType.DatePicker &&
                                    !this._isOfflineValuesLoaded
                                ) {
                                    const sto = setTimeout(() => {
                                        this.updateModel(fieldValue);
                                        this._isOfflineValuesLoaded = true;
                                        clearTimeout(sto);
                                    }, 4000);
                                }
                                this.saveToEstimate(fieldValue);
                            });
                        }
                    });
                });
            }
        ).catch(this.handleExcelErrors);
    }

    public init(estimate: ExcelEstimate): void {
        this._excelEstimate.next(estimate);
    }

    public getExcelEstimate(): ExcelEstimate | undefined {
        return this._excelEstimate.getValue();
    }

    public Save(): void {
        Excel.run({ delayForCellEdit: true }, async (context) => {
            const json = JSON.stringify(this.getExcelEstimate());
            context.workbook.settings.add("estimate", json);
            await context.sync();
        });
    }

    public async loadExcelStorageService(): Promise<void> {
        return await Excel.run({ delayForCellEdit: true }, async (context) => {
            this.inputBuilder.industry0.context = "";
            this.inputBuilder.industry1.context = "";
            this.inputBuilder.industry2.context = "";
            this.inputBuilder.industry3.context = "";
            this.inputBuilder.industry4.context = "";
            const settings = context.workbook.settings;
            const estimate = settings.getItemOrNullObject("estimate");
            await context.sync();

            // if the estimate is null return
            if (estimate.isNullObject) {
                return;
            }

            // load the estimate
            estimate.load();
            await context.sync().then(() => {
                this._excelEstimate.next(JSON.parse(estimate.value));
                const fields = this.getExcelEstimate();

                if (fields == null) {
                    return;
                }
                Object.keys(fields)
                    .filter((x) => fields[x] && fields[x].reference)
                    .forEach((item) => {
                        const currentFieldValue = fields[item];
                        if (currentFieldValue.reference) {
                            this.setCellBinding(
                                item,
                                currentFieldValue.reference,
                                currentFieldValue.fieldType
                            );
                        }
                    });
            });
        }).catch(this.handleExcelErrors);
    }

    private sheetWithSpace(sheetReference: string): string {
        let reference = "";
        if (new RegExp("\\s+").test(sheetReference)) {
            if (sheetReference.split("!")[0].indexOf("'") !== 0) {
                reference = `'${sheetReference.split("!")[0]}'!${
                    sheetReference.split("!")[
                        sheetReference.split("!").length - 1
                    ]
                }`;
            } else {
                reference = sheetReference;
            }
        } else {
            reference = sheetReference;
        }
        return reference;
    }

    public removeSheetWithSpace(sheetReference: string): string {
        let reference = "";
        if (new RegExp("\\s+").test(sheetReference)) {
            reference = `${sheetReference.split("!")[0].split(`'`)[1]}!${
                sheetReference.split("!")[sheetReference.split("!").length - 1]
            }`;
        } else {
            reference = sheetReference;
        }
        return reference;
    }

    public getuserprofile(profile: User): void {
        this.userprofile = profile;
    }

    public validatedate(dateString: string): boolean {
        const dateformat =
            /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;

        // Match the date format through regular expression
        if (dateString.match(dateformat)) {
            const operator = dateString.split("/");

            // Extract the string into month, date and year
            let datepart: string[] = [];
            if (operator.length > 1) {
                datepart = dateString.split("/");
            }
            const month = parseInt(datepart[0], 10);
            const day = parseInt(datepart[1], 10);
            const year = parseInt(datepart[2], 10);

            // Create list of days of a month
            const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (month === 1 || month > 2) {
                if (day > ListofDays[month - 1]) {
                    /// This check is for Confirming that the date is not out of its range
                    return false;
                }
            } else if (month === 2) {
                let leapYear = false;
                if ((!(year % 4) && year % 100) || !(year % 400)) {
                    leapYear = true;
                }
                if (leapYear === false && day >= 29) {
                    return false;
                } else if (leapYear === true && day > 29) {
                    return false;
                }
            }
        } else {
            return false;
        }
        return true;
    }

    public datecalculations(
        NgbDateStructdate: { day: number; month: number; year: number },
        model: FieldData
    ): boolean {
        let display = false;
        const inputdate = new Date(
            NgbDateStructdate.year,
            NgbDateStructdate.month - 1,
            NgbDateStructdate.day
        );
        const mod1leastdate = new Date(1998, 11, 31);
        const mod2leastdate = new Date(2014, 2, 31);
        const mod3leastdate = new Date(2014, 2, 31);
        const datenow = new Date();
        const year = datenow.getFullYear();
        const last2year = new Date(year - 2, 11, 31);
        const trialstartdate = new Date(2016, 2, 31);
        const trialenddate = new Date(2016, 6, 31);
        const tovalidatedate =
            inputdate.getUTCMonth() +
            1 +
            "/" +
            inputdate.getUTCDate() +
            "/" +
            inputdate.getUTCFullYear();
        const validdate = this.validatedate(tovalidatedate);
        if (validdate === true) {
            if (inputdate > datenow) {
                this.displayDialog(ExcelDialogType.valuationfuturedate);
                display = true;
            }
            this.userprofile.subscriptionDetails.forEach((element) => {
                if (
                    element.productType === "Coc" &&
                    model.source === "valuationDate" &&
                    (element.level === "Pro" || element.level === "Enterprise")
                ) {
                    if (inputdate < mod1leastdate) {
                        this.displayDialog(
                            ExcelDialogType.valuationDateunavailablemod1
                        );
                        display = true;
                    } else {
                        model.value = NgbDateStructdate;
                    }
                } else if (
                    element.level === "Basic" &&
                    element.productType === "Coc" &&
                    model.source === "valuationDate"
                ) {
                    if (inputdate < last2year && display === false) {
                        this.displayDialog(
                            ExcelDialogType.valuationdateunavilablebasic
                        );
                        display = true;
                    }
                } else if (
                    element.level === "Trial" &&
                    element.productType === "Coc" &&
                    model.source === "valuationDate"
                ) {
                    if (inputdate < trialstartdate) {
                        this.displayDialog(ExcelDialogType.Trialdate);
                        display = true;
                    } else if (inputdate > trialenddate) {
                        this.displayDialog(ExcelDialogType.Trialdate);
                        display = true;
                    } else {
                        model.value = NgbDateStructdate;
                    }
                }
                if (
                    element.productType === "Usi" &&
                    model.source === "benchMarkingValuationDate" &&
                    (element.level === "Pro" || element.level === "Enterprise")
                ) {
                    if (inputdate < mod2leastdate) {
                        this.displayDialog(
                            ExcelDialogType.valuationDateunavailablemod2
                        );
                        display = true;
                    } else {
                        model.value = NgbDateStructdate;
                    }
                } else if (
                    element.level === "Basic" &&
                    element.productType === "Usi" &&
                    model.source === "benchMarkingValuationDate"
                ) {
                    if (inputdate < last2year && display === false) {
                        this.displayDialog(
                            ExcelDialogType.valuationdateunavilablebasic
                        );
                        display = true;
                    }
                } else if (
                    element.level === "Trial" &&
                    element.productType === "Usi" &&
                    model.source === "benchMarkingValuationDate"
                ) {
                    if (inputdate < trialstartdate) {
                        this.displayDialog(ExcelDialogType.Trialdate);
                        display = true;
                    } else if (inputdate > trialenddate) {
                        this.displayDialog(ExcelDialogType.Trialdate);
                        display = true;
                    } else {
                        model.value = NgbDateStructdate;
                    }
                }
                if (
                    element.productType === "Ini" &&
                    model.source === "intlBenchMarkingValuationDate" &&
                    (element.level === "Pro" || element.level === "Enterprise")
                ) {
                    if (inputdate < mod3leastdate) {
                        this.displayDialog(
                            ExcelDialogType.valuationDateunavailablemod3
                        );
                        display = true;
                    } else {
                        model.value = NgbDateStructdate;
                    }
                } else if (
                    element.level === "Basic" &&
                    element.productType === "Ini" &&
                    model.source === "intlBenchMarkingValuationDate"
                ) {
                    if (inputdate < last2year && display === false) {
                        this.displayDialog(
                            ExcelDialogType.valuationdateunavilablebasic
                        );
                        display = true;
                    }
                } else if (
                    element.level === "Trial" &&
                    element.productType === "Ini" &&
                    model.source === "intlBenchMarkingValuationDate"
                ) {
                    if (inputdate < trialstartdate) {
                        this.displayDialog(ExcelDialogType.Trialdate);
                        display = true;
                    } else if (inputdate > trialenddate) {
                        this.displayDialog(ExcelDialogType.Trialdate);
                        display = true;
                    } else {
                        model.value = NgbDateStructdate;
                    }
                }
                if (
                    element.productType === "Ine" &&
                    model.source === "intlCOCValuationDate" &&
                    (element.level === "Pro" || element.level === "Enterprise")
                ) {
                    if (inputdate < mod2leastdate) {
                        this.displayDialog(
                            ExcelDialogType.valuationDateunavailablemod2
                        );
                        display = true;
                    } else {
                        model.value = NgbDateStructdate;
                    }
                } else if (
                    element.level === "Basic" &&
                    element.productType === "Ine" &&
                    model.source === "intlCOCValuationDate"
                ) {
                    if (inputdate < last2year && display === false) {
                        this.displayDialog(
                            ExcelDialogType.valuationdateunavilablebasic
                        );
                        display = true;
                    }
                } else if (
                    element.level === "Trial" &&
                    element.productType === "Ine" &&
                    model.source === "intlCOCValuationDate"
                ) {
                    if (inputdate < trialstartdate) {
                        this.displayDialog(ExcelDialogType.Trialdate);
                        display = true;
                    } else if (inputdate > trialenddate) {
                        this.displayDialog(ExcelDialogType.Trialdate);
                        display = true;
                    } else {
                        model.value = NgbDateStructdate;
                    }
                }
            });
        } else {
            if (display === false) {
                this.displayDialog(ExcelDialogType.valuationinvaliddate);
                display = true;
            }
        }
        return display;
    }

    public async getCellValue(model: FieldData): Promise<void> {
        const fields = this.getExcelEstimate();
        if (fields == null) {
            return;
        }

        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                const range = context.workbook.getSelectedRange();
                range.load(["address", "cellCount", "values"]);
                const activeWorksheet =
                    context.workbook.worksheets.getActiveWorksheet();
                const sheet = activeWorksheet.load([
                    "id",
                    "name",
                    "protection/protected",
                ]);
                return await context.sync().then(async () => {
                    if (new RegExp("\\s+").test(sheet.name)) {
                        sheet.name = sheet.name.replace(/'/g, '"');
                    }
                    sheet.load(["id", "name", "protection/protected"]);
                    return await context.sync().then(() => {
                        let industryExists = false;
                        // if the worksheet is protected display an error message
                        if (sheet.protection.protected) {
                            this.displayDialog(ExcelDialogType.ProtectedSheet);

                            return false;
                        }

                        // if multiple cells are selected display an error message
                        if (range.cellCount !== 1) {
                            this.displayDialog(
                                ExcelDialogType.MultipleCellsSelected
                            );

                            return false;
                        }
                        
                        // if the value in the cell is not a number display an error message
                        if (
                            range.values[0][0] !== "" &&
                            typeof range.values[0][0] !== "number" &&
                            model.fieldType !== FieldType.DatePicker && model.fieldType !== FieldType.IntlCombobox
                        ) {
                            this.displayDialog(ExcelDialogType.NaN);

                            return false;
                        }

                        const formattedAddress: string = this.formatCellAddress(
                            range.address
                        );
                        // if the value in the cell is already bound somewhere else display an error message
                        if (
                            this.getValueFromAddress(
                                formattedAddress,
                                sheet.id,
                                fields,
                                model
                            ) != null
                        ) {
                            this.displayDialog(
                                ExcelDialogType.ValueAlreadyBound
                            );

                            return false;
                        }
                        // let display = false;
                        // set the value in the add-in to the value in the cell
                        if (model.fieldType === FieldType.DatePicker) {
                            if (range.values[0][0] !== "") {
                                const datecode = range.values[0][0];
                                const date =
                                    ExcelStorageService.SerialDateToJSDate(
                                        datecode
                                    );
                                const NgbDateStructdate = {
                                    day: date.getUTCDate(),
                                    month: date.getUTCMonth() + 1,
                                    year: date.getUTCFullYear(),
                                };
                                // display = this.datecalculations(NgbDateStructdate, model);
                                model.value = NgbDateStructdate;
                            } else {
                                model.value = "";
                                if (model.source === InputName.ValuationDate) {
                                    this.fieldService.resetAllvaluestoEmpty(
                                        this
                                    );
                                }
                            }
                        } else {
                            if (
                                model.value &&
                                model.valueType === ValueType.Industry &&
                                typeof model.value !== "string"
                            ) {
                                if (
                                    model.value.GicIndustryCode !== null &&
                                    range.values[0][0].toString() ===
                                        model.value.GicIndustryCode.toString()
                                ) {
                                    industryExists = true;
                                } else if (
                                    model.value.SicIndustryCode !== null &&
                                    range.values[0][0].toString() ===
                                        model.value.SicIndustryCode.toString()
                                ) {
                                    industryExists = true;
                                }
                            }
                            model.value = range.values[0][0];
                            if (
                                this.inputBuilder.valuationDate.value !==
                                    null &&
                                model.value &&
                                (model.source === InputName.Industry0 ||
                                    model.source === InputName.Industry1 ||
                                    model.source === InputName.Industry2 ||
                                    model.source === InputName.Industry3 ||
                                    model.source === InputName.Industry4)
                            ) {
                                let date = this.inputBuilder.valuationDate.value
                                    ? ngbDateStructToString(
                                          this.inputBuilder.valuationDate.value
                                      )
                                    : undefined;
                                if (date && date.includes("T")) {
                                    date = date.split("T")[0];
                                }
                                model.value = this.getIndustry(
                                    this.inputBuilder.intlIndustries.value,
                                    date,
                                    model.value.toString(),
                                    model
                                );
                            }
                        }

                        model.reference = formattedAddress;
                        model.worksheetId = activeWorksheet.id;
                        if (formattedAddress) {
                            this.setCellBinding(
                                model.source,
                                formattedAddress,
                                model.fieldType
                            );
                        }
                        this.formatBoundCellRange(range);
                        if (model.value) {
                            if (
                                model.value !== "" &&
                                model.valueType !== ValueType.Industry
                            ) {
                                this.updateModel(model);
                            } else if (
                                model.value !== "" &&
                                model.valueType === ValueType.Industry
                            ) {
                                this.fieldService.updateIndustry(
                                    model,
                                    this,
                                    industryExists
                                );
                            } else {
                                this.fieldService.resetIndustryvaluestoEmpty(
                                    model,
                                    this
                                );
                                // this.removeBinding(model);
                            }
                        } else {
                            model.value = "";
                            this.Save();
                        }
                        this.formatBoundCellRange(range);
                        return true;
                    });
                });
            }
        )
            .then((success: boolean) => {
                if (model.config) {
                    if (this.isAlreadyBinded) {
                        this.isAlreadyBinded = false;
                        model.config.disabled = true;
                    } else {
                        model.config.disabled = success;
                    }
                }
                this.saveToEstimate(model);
            })
            .catch(this.handleExcelErrors);
    }

    public getIndustry(
        industries: Industry[],
        valuationDate: any,
        value: string,
        model: FieldData
    ): Industry {
        let _industry!: Industry;
        if (industries.length === 0 && this.inputBuilder.valuationDate.value) {
            const date = ngbDateStructToString(
                this.inputBuilder.valuationDate.value
            );
            const request = this.industryService.read(date);
            request.onceDefined((param: Industry[]) => {
                this.inputBuilder.intlIndustries.value = param;
                this.saveToEstimate(this.inputBuilder.intlIndustries);
            });
        } else if (
            valuationDate &&
            valuationDate > "2020-09-30" &&
            industries.length > 0
        ) {
            const industry = industries.filter(
                (x: any) => x.GicIndustryCode.toString() === value
            );
            if (industry && industry.length > 0) {
                _industry = industry[0];
            } else {
                if (!this.isNotAvailabledialogShowen) {
                    this.isNotAvailabledialogShowen = true;
                    this.notAvailableDialogRef = this.dialog.open(
                        CommonDialogInfoComponent,
                        {
                            data: dt.industryNotAvailable,
                            panelClass: "dialog-customcss",
                        }
                    );
                }
                this.notAvailableDialogRef.afterClosed().subscribe(() => {
                    this.isNotAvailabledialogShowen = false;
                });
                this.fieldService.resetIndustryvaluestoEmpty(model, this);
            }
        } else if (
            valuationDate &&
            valuationDate <= "2020-09-30" &&
            industries.length > 0
        ) {
            const industry = industries.filter(
                (x: any) => x.SicIndustryCode.toString() === value
            );
            if (industry && industry.length > 0) {
                _industry = industry[0];
            } else {
                if (!this.isNotAvailabledialogShowen) {
                    this.isNotAvailabledialogShowen = true;
                    this.notAvailableDialogRef = this.dialog.open(
                        CommonDialogInfoComponent,
                        {
                            data: dt.industryNotAvailable,
                            panelClass: "dialog-customcss",
                        }
                    );
                }
                this.notAvailableDialogRef.afterClosed().subscribe(() => {
                    this.isNotAvailabledialogShowen = false;
                });

                this.fieldService.resetIndustryvaluestoEmpty(model, this);
            }
        } else if (!valuationDate) {
            if (!this.isNotSelecteddialogShowen) {
                this.isNotSelecteddialogShowen = true;
                this.notSelectedDialogRef = this.dialog.open(
                    CommonDialogInfoComponent,
                    {
                        data: dt.dateNotSelected,
                        panelClass: "dialog-customcss",
                    }
                );

                this.notSelectedDialogRef.afterClosed().subscribe(() => {
                    this.isNotSelecteddialogShowen = false;
                });
            }
        }
        return _industry;
    }

    public async setCellValue(model: FieldData): Promise<void> {
        const fields = this.getExcelEstimate();
        if (fields == null) {
            return;
        }

        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                // gets the value and the address of the selected cell
                const range = context.workbook.getActiveCell();
                range.load(["values", "address", "cellCount"]);

                // gets the id of the current worksheet
                const activeWorksheet =
                    context.workbook.worksheets.getActiveWorksheet();
                const sheet = activeWorksheet.load([
                    "id",
                    "name",
                    "protection/protected",
                ]);
                return await context.sync().then(async () => {
                    if (new RegExp("\\s+").test(sheet.name)) {
                        sheet.name = sheet.name.replace(/'/g, '"');
                    }
                    sheet.load(["id", "name", "protection/protected"]);
                    return await context.sync().then(async () => {
                        // if the worksheet is protected display an error message
                        if (sheet.protection.protected) {
                            this.displayDialog(ExcelDialogType.ProtectedSheet);
                            return null;
                        }

                        // if multiple cells are selected display an error message
                        if (range.cellCount !== 1) {
                            this.displayDialog(
                                ExcelDialogType.MultipleCellsSelected
                            );
                            return null;
                        }

                        // if the cell is not empty display an error message
                        if (range.values[0][0] !== "") {
                            this.displayDialog(ExcelDialogType.CellNotEmpty);
                            return null;
                        }

                        // set the value in the cell to the add-in
                        range.values = [[model.value]];
                        if (range.address) {
                            // this.setCellBinding(model.source, range.address, model.fieldType);
                            this.setOutputCellBinding(
                                model.source,
                                range.address,
                                model.fieldType
                            );
                        }
                        // if the value was already bound to another cell, remove the older binding
                        if (model.reference) {
                            const oldWorksheetname = this.getWorksheetName(
                                model.reference
                            );
                            const oldCellname = this.getCellName(
                                model.reference
                            );
                            const oldSheet =
                                context.workbook.worksheets.getItem(
                                    oldWorksheetname
                                );
                            const oldRange = oldSheet.getRange(oldCellname);
                            oldRange.load(["values", "address", "cellCount"]);
                            await context.sync().then(() => {
                                oldRange.clear();
                                this.clearCellFormatting(oldRange);
                            });
                        }
                        this.formatBoundCellRange(range);
                        const estimateField: FieldData = fields[model.source];
                        estimateField.reference = this.removeSheetWithSpace(
                            range.address
                        );
                        estimateField.worksheetId = sheet.id;
                        this.Save();

                        return range.address;
                    });
                });
            }
        ).catch(this.handleExcelErrors);
    }

    private formatBoundCellRange(range: Excel.Range): void {
        const skyblue = "#4c9fc8";
        range.format.fill.color = "#ededed";
        range.format.font.color = skyblue;
        range.format.borders.getItem("InsideHorizontal").color = skyblue;
        range.format.borders.getItem("InsideVertical").color = skyblue;
        range.format.borders.getItem("EdgeBottom").color = skyblue;
        range.format.borders.getItem("EdgeLeft").color = skyblue;
        range.format.borders.getItem("EdgeRight").color = skyblue;
        range.format.borders.getItem("EdgeTop").color = skyblue;
    }

    private clearCellFormatting(range: Excel.Range): void {
        range.format.fill.clear();
        range.format.font.color = "#000000";
        range.format.borders.getItem("InsideHorizontal").style = "None";
        range.format.borders.getItem("InsideVertical").style = "None";
        range.format.borders.getItem("EdgeBottom").style = "None";
        range.format.borders.getItem("EdgeLeft").style = "None";
        range.format.borders.getItem("EdgeRight").style = "None";
        range.format.borders.getItem("EdgeTop").style = "None";
    }

    public saveToEstimate(model: FieldData): void {
        if (model.value === "Infinity%") {
            model.value = "N/A";
        }
        if (model.value === "N/A") {
            model.decimalValue = 0;
        }
        const fields: ExcelEstimate | undefined = this.getExcelEstimate();
        if (fields == null) {
            return;
        }
        const tempFields = Object.assign(fields);
        tempFields[model.source] = model;
        Object.keys(fields)
            .filter((x) => fields[x])
            .forEach((item) => {
                if (fields[item].relatedInputs.length > 0) {
                    for (
                        let i = 0;
                        i <= fields[item].relatedInputs.length - 1;
                        i++
                    ) {
                        if (
                            fields[item].relatedInputs[i].source ===
                            model.source
                        ) {
                            tempFields[item].relatedInputs[i] = model;
                        }
                    }
                }
            });
        // console.log(tempFields);
        this._excelEstimate.next(tempFields);

        this.Save();
        if (model.reference) {
            this.updateValue(model);
        }
    }

    private getWorksheetName(reference: string): string {
        return reference.split("!")[0];
    }

    private getCellName(reference: string): string {
        return reference.split("!")[1];
    }

    private async updateValue(model: FieldData): Promise<void> {
        if (model.reference == null) {
            return;
        }

        const fields = this.getExcelEstimate();
        if (fields == null) {
            return;
        }
        let display = false;
        const worksheetName = this.getWorksheetName(model.reference);
        const cellName = this.getCellName(model.reference);

        return Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                const sheet =
                    context.workbook.worksheets.getItem(worksheetName);
                const range = sheet.getRange(cellName);
                range.load(["values"]);

                return await context.sync().then(() => {
                    if (range.values[0][0] === model.value) {
                        return;
                    }

                    if (model.valueType === ValueType.Industry) {
                        if (
                            model.value !== "N/A" &&
                            typeof model.value === "string" &&
                            model.value.includes(" ") &&
                            range.values[0][0] !== ""
                        ) {
                            const splitted = model.value.split(" ", 2);
                            let date = this.inputBuilder.valuationDate.value
                                ? ngbDateStructToString(
                                      this.inputBuilder.valuationDate.value
                                  )
                                : undefined;
                            if (date && date.includes("T")) {
                                date = date.split("T")[0];
                            }
                            model.value = this.getIndustry(
                                this.inputBuilder.intlIndustries.value,
                                date,
                                splitted[1],
                                model
                            );
                            if (!model.value) {
                                model.value = "N/A";
                                this.modelValueupdated = true;
                            }
                            if (
                                model.value &&
                                typeof model.value === "object" &&
                                model.value.GicIndustryCode
                            ) {
                                if (
                                    range.values[0][0].toString() ===
                                    model.value.GicIndustryCode.toString()
                                ) {
                                    model.context =
                                        "GICS " +
                                        model.value.GicIndustryCode +
                                        " - " +
                                        model.value.Sector;
                                    return;
                                } else {
                                    this.offlineIndustryChanges(model, range);
                                }
                            } else if (
                                model.value &&
                                typeof model.value === "object" &&
                                model.value.SicIndustryCode
                            ) {
                                if (
                                    range.values[0][0].toString() ===
                                    model.value.SicIndustryCode.toString()
                                ) {
                                    model.context =
                                        "GICS " +
                                        model.value.SicIndustryCode +
                                        " - " +
                                        model.value.Sector;
                                    return;
                                } else {
                                    this.offlineIndustryChanges(model, range);
                                }
                            }
                        } else if (
                            model.value &&
                            typeof model.value === "object" &&
                            model.value.GicIndustryCode
                        ) {
                            if (
                                range.values[0][0].toString() ===
                                model.value.GicIndustryCode.toString()
                            ) {
                                model.context =
                                    "GICS " +
                                    model.value.GicIndustryCode +
                                    " - " +
                                    model.value.Sector;
                                return;
                            } else {
                                this.offlineIndustryChanges(model, range);
                            }
                        } else if (
                            model.value &&
                            typeof model.value === "object" &&
                            model.value.SicIndustryCode
                        ) {
                            if (
                                range.values[0][0].toString() ===
                                model.value.SicIndustryCode.toString()
                            ) {
                                model.context =
                                    "SIC " +
                                    model.value.SicIndustryCode +
                                    " - " +
                                    model.value.Sector;
                                return;
                            } else {
                                this.offlineIndustryChanges(model, range);
                            }
                        } else if (
                            model.value === "N/A" &&
                            !this.modelValueupdated
                        ) {
                            this.offlineIndustryChanges(model, range);
                        } else if (
                            model.value === "" &&
                            !this.modelValueupdated
                        ) {
                            this.offlineIndustryChanges(model, range);
                        }
                    }

                    // If the input is other than number just save this after assigning empty to the fieild Add-in
                    if (
                        model.fieldType === FieldType.Input ||
                        model.fieldType === FieldType.TypeAhead
                    ) {
                        if (model.valueType === ValueType.Industry) {
                            if (typeof model.value !== "string") {
                                // if (model.value && model.value.GicIndustryCode !== null) {
                                //     model.context = 'GICS ' + model.value.GicIndustryCode + ' - ' + model.value.Sector;
                                // } else if (model.value && model.value.SicIndustryCode !== null) {
                                //     model.context = 'SIC ' + model.value.SicIndustryCode + ' - ' + model.value.Sector;
                                // } else {
                                //     range.values[0][0] = null;
                                // }
                                this.intiateUpdateDataSummary();
                                this.Save();
                            }
                            if (typeof model.value === "string") {
                                if (range.values[0][0] === "") {
                                    model.value = "N/A";
                                    model.context = "N/A";
                                    this.modelValueupdated = true;
                                }
                                this.intiateUpdateDataSummary();
                                this.Save();
                            }
                            return;
                        } else {
                            if (typeof range.values[0][0] === "number") {
                                model.value = range.values[0][0];
                                fields[model.source] = model;
                                this.updateModel(model);
                                this.Save();
                            } else {
                                model.value = "";
                                this.Save();
                            }
                        }
                    } else if (model.fieldType === FieldType.Output) {
                        range.values = [
                            [
                                Util.isNullOrEmpty(model.value)
                                    ? ""
                                    : model.value,
                            ],
                        ];
                    } else if (model.fieldType === FieldType.DatePicker) {
                        if (range.values[0][0] !== "") {
                            const datecode = range.values[0][0];
                            const date =
                                ExcelStorageService.SerialDateToJSDate(
                                    datecode
                                );
                            const NgbDateStructdate = {
                                day: date.getUTCDate(),
                                month: date.getUTCMonth() + 1,
                                year: date.getUTCFullYear(),
                            };
                            display = this.datecalculations(
                                NgbDateStructdate,
                                model
                            );
                            if (display === false) {
                                model.value = NgbDateStructdate;
                                if (model.source === "valuationDate") {
                                    this.previousdate1 = range.values[0][0];
                                } else if (
                                    model.source === "benchMarkingValuationDate"
                                ) {
                                    this.previousdate2 = range.values[0][0];
                                } else if (
                                    model.source ===
                                    "intlBenchMarkingValuationDate"
                                ) {
                                    this.previousdate3 = range.values[0][0];
                                }else if(model.source === "intlCOCValuationDate"){
                                    this.previousdate4 = range.values[0][0];
                                }
                            } else {
                                if (model.source === "valuationDate") {
                                    range.values = [[this.previousdate1]];
                                } else if (
                                    model.source === "benchMarkingValuationDate"
                                ) {
                                    range.values = [[this.previousdate2]];
                                } else if (
                                    model.source ===
                                    "intlBenchMarkingValuationDate"
                                ) {
                                    range.values = [[this.previousdate3]];
                                } else if(
                                    model.source ===
                                    "intlCOCValuationDate"
                                ){
                                    range.values = [[this.previousdate4]];
                                }
                            }
                        } else {
                            model.value = "";
                            fields[model.source] = model;
                        }
                        this.Save();
                    } else {
                        range.values = [[model.value]];
                    }
                });
            }
        ).catch(this.handleExcelErrors);
    }

    private offlineIndustryChanges(model: FieldData, range: Excel.Range): void {
        if (
            this.inputBuilder.valuationDate.value !== null &&
            (model.source === InputName.Industry0 ||
                model.source === InputName.Industry1 ||
                model.source === InputName.Industry2 ||
                model.source === InputName.Industry3 ||
                model.source === InputName.Industry4)
        ) {
            let date = this.inputBuilder.valuationDate.value
                ? ngbDateStructToString(this.inputBuilder.valuationDate.value)
                : undefined;
            if (date && date.includes("T")) {
                date = date.split("T")[0];
            }
            model.value = this.getIndustry(
                this.inputBuilder.intlIndustries.value,
                date,
                range.values[0][0].toString(),
                model
            );
            if (model.value && model.value.GicIndustryCode !== null) {
                model.context =
                    "GICS " +
                    model.value.GicIndustryCode +
                    " - " +
                    model.value.Sector;
            } else if (model.value && model.value.SicIndustryCode !== null) {
                model.context =
                    "SIC " +
                    model.value.SicIndustryCode +
                    " - " +
                    model.value.Sector;
            }
            if (!model.value && !this.modelValueupdated) {
                model.value = "N/A";
                model.context = "N/A";
                this.modelValueupdated = true;
                this.intiateUpdateDataSummary();
            } else {
                this.intiateUpdateDataSummary();
            }
        }
        if (
            typeof model.value !== "string" &&
            model.valueType === ValueType.Industry
        ) {
            this.updateModel(model);
        }

        this.Save();
    }

    public focusToCell(fieldValue: FieldData): void {
        if (fieldValue.reference == null) {
            return;
        }

        const worksheetName = this.getWorksheetName(fieldValue.reference);
        const cellName = this.getCellName(fieldValue.reference);
        Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                const sheet =
                    context.workbook.worksheets.getItem(worksheetName);
                const range = sheet.getRange(cellName);
                range.select();
                return await context.sync();
            }
        ).catch(this.handleExcelErrors);
    }

    public formatCellAddress(address: string): string {
        let worksheetName: string = this.getWorksheetName(address);
        const cellName: string = this.getCellName(address);

        if (
            worksheetName[0] === "'" &&
            worksheetName[worksheetName.length - 1] === "'"
        ) {
            worksheetName = worksheetName.substring(
                1,
                worksheetName.length - 1
            );
        }

        return `${worksheetName}!${cellName}`;
    }

    public async removeBinding(model: FieldData): Promise<void> {
        // if the model doesn't have a linked cell return
        if (model.reference == null) {
            return;
        }

        // retrieves the binding for the model and removes it
        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                const bindings = context.workbook.bindings;
                bindings.load("items");

                return await context.sync().then(() => {
                    const boundValue = bindings.getItem(model.source);
                    this.deleteValue(model, boundValue);
                });
            }
        )
            .then(() => {
                if (model.config == null) {
                    return;
                }

                if (
                    model.fieldType === FieldType.Input ||
                    model.fieldType === FieldType.DatePicker ||
                    model.fieldType === FieldType.TypeAhead
                ) {
                    model.config.disabled = false;
                }
            })
            .catch(this.handleExcelErrors);
    }
    public intiateUpdateDataSummary(): void {
        if (!this._isDynamicDataSummary) {
            this._isDynamicDataSummary = true;
            this.updateDataSummary();
        }
    }
    public updateDataSummary(): void {
        const sto = setTimeout(() => {
            this._isDynamicDataSummary = false;
            if (this.inputBuilder.isDataSummaryExported.value) {
                Excel.run(
                    { delayForCellEdit: true },
                    async (context: Excel.RequestContext) => {
                        const sheets = context.workbook.worksheets;
                        sheets.load("items/id");
                        return await context.sync().then(async () => {
                            const dataSummarySheet = sheets.items.find(
                                (x) =>
                                    x.id ===
                                    this.inputBuilder.isDataSummaryExported
                                        .value
                            );
                            if (dataSummarySheet) {
                                this.exportDataSummary();
                            }
                        });
                    }
                );
            }
            clearTimeout(sto);
        }, 10000);
    }
    public exportDataSummary(): void {
        const fields = this.getExcelEstimate();
        if (fields == null) {
            return;
        }

        Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                const sheets = context.workbook.worksheets;
                sheets.load("items/id");

                return await context.sync().then(async () => {
                    // checks if the data summary worksheet already exists and deletes it if it does
                    const dataSummarySheet = sheets.items.find(
                        (x) =>
                            x.id ===
                            this.inputBuilder.isDataSummaryExported.value
                    );
                    let sheet: any = null;
                    if (
                        dataSummarySheet &&
                        this.inputBuilder.isDataSummaryExported.value
                    ) {
                        // dataSummarySheet.delete();
                        sheet = dataSummarySheet;
                        dataSummarySheet.protection.unprotect();
                        dataSummarySheet
                            .getUsedRangeOrNullObject()
                            .delete(Excel.DeleteShiftDirection.up);
                    } else {
                        sheet = sheets.add("Kroll Data Summary");
                        sheet.load("id");
                    }

                    // creates the data summary worksheet

                    return await context.sync().then(async () => {
                        // saves the id of the newly created sheet. Useful to track the sheet in case the user renames it.
                        // this._dataSummaryWorksheetId = sheet.id;
                        if (
                            !this.inputBuilder.isDataSummaryExported.value ||
                            this.inputBuilder.isDataSummaryExported.value !==
                                sheet.id
                        ) {
                            this.inputBuilder.isDataSummaryExported.value =
                                sheet.id;
                            this.saveToEstimate(
                                this.inputBuilder.isDataSummaryExported
                            );
                        }
                        // creates the data summary table

                        // TODO: this is temporary and will be replaced by the data-through date
                        // adding the label to the sheet in cell A1
                        const rangeLabel = sheet.getRange("A1");
                        rangeLabel.values = [["Valuation Date"]];

                        // adding the date to the sheet in cell B1
                        const date: FieldData = fields[InputName.ValuationDate];
                        let dateToString = `${date.value.month}/${date.value.day}/${date.value.year}`;
                        if (
                            dateToString &&
                            dateToString.includes("undefined")
                        ) {
                            dateToString = "";
                        }
                        const rangeDate = sheet.getRange("B1");
                        rangeDate.values = [[dateToString]];
                        rangeDate.format.horizontalAlignment =
                            Excel.HorizontalAlignment.left;

                        // adding the last modified label in cell A2
                        const lastModifiedLabel = sheet.getRange("A2");
                        lastModifiedLabel.values = [["Last Modified"]];

                        // adding the last modified date in cell B2
                        const lastModifiedDate: Date = new Date();
                        const lastModifiedDateRange = sheet.getRange("B2");
                        lastModifiedDateRange.values = [
                            [lastModifiedDate.toString()],
                        ];

                        // adding the data summary table on the 3rd row
                        const dataSummaryTable = sheet.tables.add(
                            "A3:G3",
                            true /*hasHeaders*/
                        );
                        sheet.getUsedRange().format.font.name = "Arial";
                        dataSummaryTable.name = "DataSummary";
                        dataSummaryTable.getHeaderRowRange().values = [
                            this.tableHeaderService.getHeaderTitles(),
                        ];
                        dataSummaryTable.getHeaderRowRange().format.fill.color =
                            "#14487F";
                        dataSummaryTable.getHeaderRowRange().format.font.color =
                            "#FFFFFF";
                        dataSummaryTable.getDataBodyRange().format.fill.color =
                            "#EDEDED";
                        dataSummaryTable.getDataBodyRange().format.font.color =
                            "#4D4D4F";
                        dataSummaryTable.getDataBodyRange().format.font.name =
                            "Arial";
                        dataSummaryTable.getDataBodyRange().format.horizontalAlignment =
                            Excel.HorizontalAlignment.left;
                        dataSummaryTable.style = "TableStyleMedium1";

                        // adds the data to the table
                        const tableData = this.getBoundData();
                        const newData = tableData.map((item) => {
                            if (item.fieldType === FieldType.DatePicker) {
                                let value = `${item.value.month}/${item.value.day}/${item.value.year}`;
                                if (value && value.includes("undefined")) {
                                    value = "";
                                }
                                return [
                                    item.reference,
                                    item.study,
                                    item.measure,
                                    item.dataPoint,
                                    value,
                                    item.dataAsOf,
                                    item.breakpoints,
                                ];
                            }
                            if (
                                item.fieldType === FieldType.TypeAhead &&
                                item.valueType === ValueType.Industry
                            ) {
                                let value =
                                    typeof item.value !== "string"
                                        ? item.context
                                        : item.value;
                                if (value && value.includes("undefined")) {
                                    value = "N/A";
                                }
                                return [
                                    item.reference,
                                    item.study,
                                    item.measure,
                                    "Industry",
                                    value,
                                    item.dataAsOf,
                                    item.breakpoints,
                                ];
                            }
                            if (
                                item.fieldType === FieldType.Output &&
                                (item.valueType ===
                                    ValueType.FullInformationBeta ||
                                    item.valueType ===
                                        ValueType.MedianSumLeveredBeta ||
                                    item.valueType ===
                                        ValueType.VasicekAdjustedBeta ||
                                    item.valueType ===
                                        ValueType.HighFinancialRiskBeta)
                            ) {
                                const dataAsOf = item.value
                                    ? item.dataAsOf
                                    : "";
                                return [
                                    item.reference,
                                    item.study,
                                    item.measure,
                                    item.dataPoint,
                                    item.value,
                                    dataAsOf,
                                    item.breakpoints,
                                ];
                            }
                            return [
                                item.reference,
                                item.study,
                                item.measure,
                                item.dataPoint,
                                item.value,
                                item.dataAsOf,
                                item.breakpoints,
                            ];
                        });
                        dataSummaryTable.rows.add(-1, newData);

                        // If the Excel host application where the code is running supports requirement set ExcelApi 1.2,
                        // the width of the columns and height of the rows are set to best fit the current data in the table
                        if (
                            Office.context.requirements.isSetSupported(
                                "ExcelApi",
                                1.2
                            )
                        ) {
                            sheet.getUsedRange().format.autofitColumns();
                            sheet.getUsedRange().format.autofitRows();
                            sheet.getUsedRange().format.font.name = "Arial";
                        }

                        // goes to the newly created worksheet
                        if (!this.inputBuilder.isDataSummaryExported.value) {
                            sheet.activate();
                        }
                        sheet.load("protection/protected");
                        var range = sheet.getUsedRange();
                        range.load("rowCount")

                        return await context.sync().then(() => {
                            this.KrollCopyrightInfo(sheet, range.rowCount);
                            if (!sheet.protection.protected) {
                                sheet.protection.protect();
                            }
                        });
                    });
                });
            }
        ).catch((error: object) => {
            if (error instanceof OfficeExtension.Error) {
                if (error.debugInfo.code === "ItemAlreadyExists") {
                    Excel.run(
                        { delayForCellEdit: true },
                        async (context: Excel.RequestContext) => {
                            const sheets = context.workbook.worksheets;
                            sheets.load("items/name");
                            return await context.sync().then(async () => {
                                // checks if the data summary worksheet already exists and deletes it if it does
                                const dataSummarySheet = sheets.items.find(
                                    (x) => x.name === "Kroll Data Summary"
                                );
                                if (dataSummarySheet) {
                                    dataSummarySheet.delete();
                                    this.inputBuilder.isDataSummaryExported.value =
                                        "";
                                    this.saveToEstimate(
                                        this.inputBuilder.isDataSummaryExported
                                    );
                                    this.exportDataSummary();
                                }
                            });
                        }
                    );
                    // console.log('Debug info: ' + JSON.stringify(error.debugInfo));
                } else {
                    this.handleExcelErrors(error);
                }
            }
        });
    }

    private KrollCopyrightInfo(sheet: Excel.Worksheet, rownum: number){
        const year  = new Date().getFullYear();
        const krollImage = sheet.getRange("A"+(rownum+2))
        var imageSrc = '=IMAGE("' + environment.urls.krollLogo + '", "KROLL", 0)';
        krollImage.formulas = [[imageSrc]]

        const krollCopyrightText = sheet.getRange("A"+(rownum+3))
        // krollCopyrightText.formulas = [['="© "&YEAR(TODAY())&" Cost of Capital Navigator by Kroll, LLC. All rights reserved."']]
        krollCopyrightText.values = [["© " + year + " Cost of Capital Navigator by Kroll, LLC. All rights reserved."]]
        krollCopyrightText.format.font.color = '#4d4d4f'
        krollCopyrightText.format.font.size = 8
        krollCopyrightText.format.font.name = 'Arial'
        krollCopyrightText.format.verticalAlignment = 'Center'
        krollCopyrightText.format.horizontalAlignment = 'Left'
    }

    private getBoundData(): FieldData[] {
        const values: FieldData[] = [];

        Object.keys(this.inputBuilder)
            .filter(
                (x) =>
                    this.inputBuilder[x].reference &&
                    (this.inputBuilder[x].tabName === Menu.Inputs ||
                        this.inputBuilder[x].tabName === Menu.RfErpData ||
                        this.inputBuilder[x].tabName === Menu.SizeRiskPremia ||
                        this.inputBuilder[x].tabName === Menu.Betas)
            )
            .forEach((key) => {
                values.push(this.inputBuilder[key]);
            });

        return values;
    }

    public displayDialog(dialogType: ExcelDialogType): void {
        const dialogName = this.excelDialogTypesBuilder.getTypeName(dialogType);
        Office.context.ui.displayDialogAsync(
            `${window.location.origin}/assets/dialog/dialog-${dialogName}.html`,
            { height: 50, width: 50 }
        );
    }
    public async clearRange(model: FieldData): Promise<void> {
        Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                const sheet = context.workbook.worksheets.getActiveWorksheet();
                const range = sheet.getRange(model.reference);
                range.clear();
                return await context.sync();
            }
        )
            .then(() => {})
            .catch(this.handleExcelErrors);
    }
    public async removeAvailbleExcel(
        excelWorkSheet: WorkbookSheet
    ): Promise<void> {
        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                // Remove the metadata before the base64-encoded string.
                const sheets = context.workbook.worksheets;
                sheets.load("name");
                return await context.sync().then(async () => {
                    // checks if the  worksheet already exists and deletes it if it does
                    const isSheet = sheets.items.find(
                        (x) => x.name === excelWorkSheet.name
                    );
                    if (isSheet) {
                        isSheet.delete();
                    }
                    await context.sync();
                });
            }
        );
    }
    public async updateAvailbleExcel(
        excelWorkSheet: WorkbookSheet
    ): Promise<void> {
        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                // Remove the metadata before the base64-encoded string.
                const sheets = context.workbook.worksheets;
                sheets.load(["name","position"]);
                return await context.sync().then(async () => {
                    // checks if the  worksheet already exists and deletes it if it does
                    const isSheet = sheets.items.find(
                        (x) => x.name === excelWorkSheet.name
                    );
                    if (isSheet && isSheet.name === 'KrollCountryRiskExport') {
                       const position = isSheet.position;
                       this.excelPosition = position;
                       this.isExportTemplateEnable = true;
                       console.log(position);
                    }
                });
            }
        );
    }
     public async updateAvailbleExcels(name: string
    ): Promise<void> {
        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                // Remove the metadata before the base64-encoded string.
                const sheets = context.workbook.worksheets;
                sheets.load(["name","position"]);
                return await context.sync().then(async () => {
                    // checks if the  worksheet already exists and deletes it if it does
                    const isSheet = sheets.items.find(
                        (x) => x.name === name
                    );
                    if (isSheet && isSheet.name === 'KrollCountryRiskExport') {
                       const position = isSheet.position;
                       this.excelPosition = position;
                       this.isExportTemplateEnable = true;
                       console.log(position);
                    }
                });
            }
        );
    }
    public async updateKrollExcelTemplateName(
        excelWorkSheet: WorkbookSheet
    ): Promise<void> {
        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                var baseSheetName = 'KrollWACCTemplate';
                var sheeetIndex = 1;
                var newSheetName = baseSheetName + sheeetIndex;
                const sheets = context.workbook.worksheets;
                sheets.load("name");
                function newSheetExists(sheetName: string){
                    return sheets.getItemOrNullObject(sheetName).load("name");
                }
                await context.sync();
                const isSheet = sheets.items.find((x) => x.name === excelWorkSheet.name);
                if(isSheet && isSheet.name.includes('KrollWACCTemplate')){
                    isSheet.load("name");
                    await context.sync();
                    let sheetExists = await newSheetExists(newSheetName);
                    await context.sync();
                    while(sheetExists !== null && sheetExists.name === newSheetName){
                        sheeetIndex++;
                        newSheetName = baseSheetName+sheeetIndex;
                        sheetExists = await newSheetExists(newSheetName);
                        await context.sync();
                    }
                    excelWorkSheet.name = newSheetName;
                    await context.sync();
                }
            }
        );
    }
    public async exportExcel(excelWorkSheet: WorkbookSheet): Promise<void> {
        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                // Remove the metadata before the base64-encoded string.
                const sheets = context.workbook.worksheets;
                sheets.load("name");
                return await context.sync().then(async () => {
                    // creates the  worksheet
                    const sheet = sheets.add(excelWorkSheet.name);
                    sheet.load("name");
                    return await context.sync().then(async () => {
                        let columns: string[] = [];
                        if (excelWorkSheet.columns) {
                            columns = this.getSheetColumnNames();
                        }
                        if (
                            excelWorkSheet.rows &&
                            excelWorkSheet.rows.length > 0
                        ) {
                            const rows = excelWorkSheet.rows;
                            for (let index = 0; index < rows.length; index++) {
                                const row = rows[index];
                                const cells = row.cells;
                                let colSpan = 0;
                                if (cells && cells.length > 0) {
                                    for (
                                        let cellindex = 0;
                                        cellindex < cells.length;
                                        cellindex++
                                    ) {
                                        const cell = cells[cellindex];
                                        const column: string[] = columns;
                                        const showcolumns = column.slice(
                                            colSpan,
                                            cell.colSpan
                                                ? colSpan + cell.colSpan
                                                : colSpan + 1
                                        );
                                        cell.colSpan
                                            ? (colSpan += cell.colSpan)
                                            : (colSpan += 1);
                                        const rangeAddress = `${
                                            showcolumns[0]
                                        }${index + 1}:${
                                            showcolumns[showcolumns.length - 1]
                                        }${index + 1}`;
                                        const range = sheet.getRange(
                                            rangeAddress.split(":")[0]
                                        );
                                        range.values = [[cell.value]];
                                        const range1 =
                                            sheet.getRange(rangeAddress);
                                        range1.merge();
                                        range1.format.columnWidth =
                                            excelWorkSheet.columns &&
                                            excelWorkSheet.columns.length >
                                                cellindex
                                                ? excelWorkSheet.columns[
                                                      cellindex
                                                  ].width
                                                    ? Number(
                                                          excelWorkSheet
                                                              .columns[
                                                              cellindex
                                                          ].width
                                                      )
                                                    : 125
                                                : 125;
                                        await this.formatStyleSheet(
                                            range1,
                                            cell
                                        );
                                    }
                                }
                            }

                            this.KrollCopyrightInfo(sheet, rows.length);
                        
                        }
                        if (
                            excelWorkSheet.name &&
                            excelWorkSheet.name.indexOf("_Co") <= 0
                        ) {
                            sheet.activate();
                        }
                        this.spinner.end();
                    });
                });

            }
        )

            .then(() => {})
            .catch(this.handleExcelErrors);
    }

    public async exportIntlCOCExcel(excelIntlSheet: WorkbookSheet): Promise<void>{
        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                // Remove the metadata before the base64-encoded string.
                const sheets = context.workbook.worksheets;
                sheets.load("name");
                    // creates the  worksheet
                    const sheet = sheets.add(excelIntlSheet.name);
                    sheet.load(["name", "position"]);
                    if(this.excelPosition !== 0){
                        sheet.position = this.excelPosition;
                    }
                    return await context.sync().then(async () => {
                        let columns: string[] = [];
                        if (excelIntlSheet.columns) {
                            columns = this.getSheetColumnNames();
                        }
                        if (
                            excelIntlSheet.rows &&
                            excelIntlSheet.rows.length > 0
                        ) 
                        {
                            const rows = excelIntlSheet.rows;
                            sheet.getRange().format.font.color = '#4d4d4f';

                            if(sheet.name === 'KrollCountryRiskExport'){
                               this.intlExportSheetChanges(sheet);

                            }
                            if(sheet.name.includes('KrollWACCTemplate')){
                                this.krollTemplateSheetChanges(sheet);

                            }
                            await context.sync();

                            for (let index = 0; index < rows.length; index++) {
                                const row = rows[index];
                                const cells = row.cells;
                                let colSpan = 0;
                                if (cells && cells.length > 0) {
                                    for (
                                        let cellindex = 0;
                                        cellindex < cells.length;
                                        cellindex++
                                    ) {
                                        const cell = cells[cellindex];
                                        const column: string[] = columns;
                                        const showcolumns = column.slice(
                                            colSpan,
                                            cell.colSpan
                                                ? colSpan + cell.colSpan
                                                : colSpan + 1
                                        );
                                        cell.colSpan
                                            ? (colSpan += cell.colSpan)
                                            : (colSpan += 1);
                                        const rangeAddress = `${
                                            showcolumns[0]
                                        }${index + 1}:${
                                            showcolumns[showcolumns.length - 1]
                                        }${index + 1}`;
                                        const range = sheet.getRange(
                                            rangeAddress.split(":")[0]
                                        );
                                        range.values = [[cell.value]];
                                        const range1 =
                                            sheet.getRange(rangeAddress);
                                        range1.merge();
                                        range1.format.columnWidth =
                                            excelIntlSheet.columns &&
                                            excelIntlSheet.columns.length >
                                                cellindex
                                                ? excelIntlSheet.columns[
                                                      cellindex
                                                  ].width
                                                    ? Number(
                                                          excelIntlSheet
                                                              .columns[
                                                              cellindex
                                                          ].width
                                                      )
                                                    : 125
                                                : 125;
                                        await this.formatStyleSheet(
                                            range1,
                                            cell
                                        );
                                    }

                                }
                            }
                        this.KrollCopyrightInfo(sheet, rows.length);    
                        }
                        if (
                            excelIntlSheet.name &&
                            excelIntlSheet.name.indexOf("_Co") <= 0
                        ) {
                            sheet.activate();
                        }
                        this.spinner.end();
                    });

            }
        )

            .then(() => {})
            .catch(this.handleExcelErrors);
    }
    public async updateKrollRiskExportSheet(excelIntlSheet: WorkbookSheet): Promise<void>{
        await Excel.run(
            { delayForCellEdit: true },
            async (context: Excel.RequestContext) => {
                // Remove the metadata before the base64-encoded string.
                const sheets = context.workbook.worksheets;
                sheets.load("name");
                return await context.sync().then(async async => {
                    let sheet = sheets.items.find(
                        (x) => x.name === excelIntlSheet.name
                    );
                    if (sheet && sheet.name === 'KrollCountryRiskExport') {
                       //clear the entire sheet 
                       sheet.getRange().clear();
                    }else{
                     sheet = sheets.add(excelIntlSheet.name);
                    }
                    sheet.load(["name", "position"]);
                    if(this.excelPosition !== 0){
                        sheet.position = this.excelPosition;
                    }
                    return await context.sync().then(async () => {
                        let columns: string[] = [];
                        if (excelIntlSheet.columns) {
                            columns = this.getSheetColumnNames();
                        }
                        if (
                            excelIntlSheet.rows &&
                            excelIntlSheet.rows.length > 0
                        ) 
                        {
                            const rows = excelIntlSheet.rows;
                            if(sheet) 
                            {
                                sheet.getRange().format.font.color = '#4d4d4f';

                                if(sheet.name === 'KrollCountryRiskExport') 
                                {
                                    this.intlExportSheetChanges(sheet);
                                }

                                if(sheet.name.includes('KrollWACCTemplate')) {
                                    this.krollTemplateSheetChanges(sheet);
                                }
                                
                                await context.sync();

                                for (let index = 0; index < rows.length; index++)
                                {
                                    const row = rows[index];
                                    const cells = row.cells;
                                    let colSpan = 0;
                                    if (cells && cells.length > 0) 
                                    {
                                        for (
                                            let cellindex = 0;
                                            cellindex < cells.length;
                                            cellindex++
                                        ) {
                                            const cell = cells[cellindex];
                                            const column: string[] = columns;
                                            const showcolumns = column.slice(
                                                colSpan,
                                                cell.colSpan
                                                    ? colSpan + cell.colSpan
                                                    : colSpan + 1
                                            );
                                            cell.colSpan
                                                ? (colSpan += cell.colSpan)
                                                : (colSpan += 1);
                                            const rangeAddress = `${
                                                showcolumns[0]
                                            }${index + 1}:${
                                                showcolumns[showcolumns.length - 1]
                                            }${index + 1}`;
                                            const range = sheet.getRange(
                                                rangeAddress.split(":")[0]
                                            );
                                            range.values = [[cell.value]];
                                            const range1 =
                                                sheet.getRange(rangeAddress);
                                            range1.merge();
                                            range1.format.columnWidth =
                                                excelIntlSheet.columns &&
                                                excelIntlSheet.columns.length >
                                                    cellindex
                                                    ? excelIntlSheet.columns[
                                                        cellindex
                                                    ].width
                                                        ? Number(
                                                            excelIntlSheet
                                                                .columns[
                                                                cellindex
                                                            ].width
                                                        )
                                                        : 125
                                                    : 125;
                                            await this.formatStyleSheet(
                                                range1,
                                                cell
                                            );
                                        }

                                    }
                                }
                                this.KrollCopyrightInfo(sheet, rows.length);
                                if (
                                    excelIntlSheet.name &&
                                    excelIntlSheet.name.indexOf("_Co") <= 0
                                ) {
                                    sheet.activate();
                                }
                                this.spinner.end();
                            }
                        }
                    });
                });
                    // creates the  worksheet
                   

            }
        )

            .then(() => {})
            .catch(this.handleExcelErrors);
    }
    public krollTemplateFormulas(sheet: Excel.Worksheet, range: string, formula: string, format?: boolean){
        const erpfomula1 = sheet.getRange(range);
        erpfomula1.formulas = [[formula]]
        if(format){
        erpfomula1.numberFormat = [["#,##0.00%"]]
        }
        if(range === 'D47' || 'D50'){
            erpfomula1.format.font.italic = true;
        }
    }
    public krollTemplateValues(sheet: Excel.Worksheet, range: string, value: any){
        const getCell = sheet.getRange(range);
        getCell.values = [[value]];
    }
    public dateFormat(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
        const getDateCell = sheet.getRange(range);
        getDateCell.load('numberFormat');
        getDateCell.numberFormat = [['mmmm d, yyyy']]
        });
        
    }
    public krollTemplateCustomCell(sheet: Excel.Worksheet, range: string){
        const getCustomCell = sheet.getRange(range);
        getCustomCell.numberFormat = [['0.00%']];
        getCustomCell.dataValidation.clear();
        getCustomCell.format.horizontalAlignment = 'Center';
        getCustomCell.format.fill.color = '#FFFFE0';
        getCustomCell.format.borders.getItem('EdgeBottom').style = 'Dot';
    }
    public krollTemplateCustomCellLeft(sheet: Excel.Worksheet, range: string){
        const getCustomCell = sheet.getRange(range);
        getCustomCell.format.horizontalAlignment = 'Left';
        getCustomCell.numberFormat = [['0.00%']];
        getCustomCell.dataValidation.clear();
        getCustomCell.format.fill.color = '#FFFFE0';
        getCustomCell.format.borders.getItem('EdgeBottom').style = 'Dot';
    }
    public krollTemplateCustomDdn(sheet: Excel.Worksheet, range: string, source: string){
        const getCustomCellddn = sheet.getRange(range);
        getCustomCellddn.values = [[source]];
        getCustomCellddn.numberFormat = [['0.00%']];
        getCustomCellddn.format.horizontalAlignment = 'Left';
        getCustomCellddn.dataValidation.rule ={
        list:{
            inCellDropDown: true,
            source: source
            }
        };
        this.errorDataValiationRule(getCustomCellddn);
        getCustomCellddn.format.fill.color = '#FFFFE0';
        getCustomCellddn.format.borders.getItem('EdgeBottom').style = 'Dot';

    }
    public errorDataValiationRule(cellRange: Excel.Range){
        cellRange.dataValidation.errorAlert = {
                                showAlert: false,
                                message: '',
                                title: '',
                                style: "Information"
                            }
    }
    public krollTemplateHeaders(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
        const headers = sheet.getRange(range);
        headers.format.fill.color = '#14487F';
        headers.format.font.color = 'White';
        headers.format.font.bold = true;
        });
    }
    public krollTemplateHeadersRow(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range)=>{
        const headerRow = sheet.getRange(range);
        headerRow.format.rowHeight = 25;
        headerRow.format.font.bold = true;
        })

    }
    public krollTemplateHeaderGrey(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
        const greyHeader = sheet.getRange(range);
        greyHeader.format.fill.color = '#cccccc';
        greyHeader.format.font.bold = true;
        greyHeader.format.borders.getItem('EdgeBottom').style = 'Continuous'
        greyHeader.format.borders.getItem('EdgeBottom').weight = 'Thick'
        })

    }
    public krollTemplateHyperlink(sheet: Excel.Worksheet, range: string, address: string){
        const hyperlinktoadd = sheet.getRange(range);
        let hyperlink = {
            textToDisplay: 'Learn More',
            screenTip: `${address} + Click once to follow. Click and hold to select this cell`,
            address: address
        };
        hyperlinktoadd.format.font.color = '#43b049';
        hyperlinktoadd.format.font.underline = 'Single';
        hyperlinktoadd.hyperlink = hyperlink;
        hyperlinktoadd.format.font.color = '#43b049';

    }
    public krollTemplateHeaderTags(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
        const headerTags = sheet.getRange(range);
        headerTags.format.font.italic = true;
        headerTags.format.font.size = 8;
        })

    }
    public krollTemplateDottedBottomBorder(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
         const borderRequired = sheet.getRange(range);
        borderRequired.format.borders.getItem('EdgeBottom').style = 'Dot';
        })
    }

    public exportSheetTextWrap(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
         const borderRequired = sheet.getRange(range);
        borderRequired.format.wrapText = true;
        })
    }

    public exportSheetFormatLeft(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
        const textFormat = sheet.getRange(range);
        textFormat.format.horizontalAlignment = 'Left';
        })
    }

    public async krollTemplateSheetChanges(sheet: Excel.Worksheet){
    sheet.getUsedRange().worksheet.showGridlines = false;

                                const greenColorRange = sheet.getRange('J2:L17');
                                greenColorRange.format.fill.color = '#008000';
                                const widthRange = sheet.getRange('L2:M44');
                                widthRange.format.columnWidth = 200;
                                const widthRange2 = sheet.getRange('K2:K44');
                                widthRange2.format.columnWidth = 150;
                                const yellowColorRange = sheet.getRange('J20:M26');
                                yellowColorRange.format.fill.color = '#FFC300'
                                const yellowColorRangevalues = sheet.getRange('J27:L35');
                                yellowColorRangevalues.format.fill.color = '#FFC300'
                                const greenColorRangeRate = sheet.getRange('J39:L40');
                                greenColorRangeRate.format.fill.color = '#008000';
                                const greenColorRangeRateDate = sheet.getRange('J42:M44');
                                greenColorRangeRateDate.format.fill.color = '#008000';
                                const investeeCountryDropdown = sheet.getRange('B2');
                                investeeCountryDropdown.format.fill.color = '#FFFFE0';
                                investeeCountryDropdown.dataValidation.rule ={
                            list:{
                                inCellDropDown: true,
                                source: `=KrollCountryRiskExport!$A$30:A$222`
                                }
                            };
                            investeeCountryDropdown.format.borders.getItem('EdgeBottom').style = 'Dot'

                            const countrydropdown = sheet.getRange('B30');
                            countrydropdown.dataValidation.rule ={
                                list:{
                                    inCellDropDown: true,
                                    source: "Investor Country,Investee Country"
                                }
                            };
                            countrydropdown.format.fill.color = '#FFFFE0';
                            countrydropdown.format.borders.getItem('EdgeBottom').style = 'Dot';

                            const waccDdn = sheet.getRange('B116');
                            waccDdn.dataValidation.rule ={
                                list:{
                                    inCellDropDown: true,
                                    source: "Yes,No"
                                }
                            }
                            waccDdn.format.fill.color = '#FFFFE0';
                            waccDdn.format.borders.getItem('EdgeBottom').style = 'Dot';

                           
                            const fisherInputsText = sheet.getRange('A43:A44');
                            fisherInputsText.merge();
                            fisherInputsText.format.rowHeight = 30;
                            fisherInputsText.format.wrapText = true;

                            const textForm = sheet.getRange('D1');
                            textForm.formulas = [['="Estimated cost of capital for an investment located in "&B2&" (the Investee Country) from the perspective of an investor based in "&A2&". Cost of Capital estimates herein are presented in both the investor and the investee country currency. The valuation date of this analysis is "&TEXT(KrollCountryRiskExport!$C$2, "mmmm dd, yyyy")&"."']];
                            const text = sheet.getRange('D1:F4');
                            text.merge();
                            // text.formulas = [['="Estimated cost of capital for an investment located in "&B2&" (the Investee Country) from the perspective of an investor based in "&A2&". Cost of Capital estimates herein are presented in both the investor and the investee country currency. The valuation date of this analysis is "&TEXT(KrollCountryRiskExport!$C$2, "mmmm dd, yyyy")&"."']];
                            // text.format.rowHeight = 35;
                            text.format.wrapText = true;
                            // text.format.verticalAlignment = 'Top';



                            const waccOptions = sheet.getRange('A117');
                            waccOptions.format.verticalAlignment = 'Top';

                            const headerRange = sheet.getRange('B55:F163');
                            headerRange.format.horizontalAlignment = 'Center';

                            const inputsAlignment = sheet.getRange('D29:D50');
                            inputsAlignment.format.horizontalAlignment = 'Left';

                            const WACCoptionTag = sheet.getRange('A117');
                            WACCoptionTag.format.rowHeight = 25;
                            this.dateFormat(sheet,['A4']);
                            this.krollTemplateHeaderTags(sheet, ['A11','A14', 'A17', 'A20', 'A23', 'A26',
                                                        'A31','A34','A37','A40','A47','A50','A66','A83','A117']);
                            this.krollTemplateHeadersRow(sheet, ['A55','A76','A96','A145']);
                            this.krollTemplateHeaders(sheet, ['A53','A74','A5','A114','A92','A143']);
                            this.krollTemplateHeaderGrey(sheet, ['A7','A28','A42']);
                            this.krollTemplateDottedBottomBorder(sheet, ['B10','B13','B16','B19', 'A150:F150','A157:F157','A163:F163']);
                            this.krollTemplateContinuousBottomBorder(sheet, ['B55','D55','F55','B57','D57','F57','B76','D76','F76'
                                                                    ,'B78','D78','F78','A90:F90','A51:F51','B96','D96','F96'
                                                                    ,'A112:F112','A141:F141','B145','D145','F145','A163:F163'
                                                                    ,'B117','D117','F117','B119','D119','F119']);

                            this.krollTemplateCustomCell(sheet, 'B147');
                            this.krollTemplateCustomCell(sheet, 'D147');
                            this.krollTemplateCustomCell(sheet, 'F147');
                            this.krollTemplateCustomCell(sheet, 'B155');
                            this.krollTemplateCustomCellLeft(sheet, 'B33');
                            this.krollTemplateCustomCellLeft(sheet, 'B39');
                            this.krollTemplateCustomCellLeft(sheet, 'B22');
                            this.krollTemplateCustomCellLeft(sheet, 'B25');

                            this.exportSheetFormatLeft(sheet, ['A4']);
                            this.krollTemplateHyperlink(sheet, 'H7', 'https://vasdc8grscoc.blob.core.windows.net/files/CountryRiskTemplate/CostOfEquityInputs.pdf');
                            this.krollTemplateHyperlink(sheet, 'H28', 'https://vasdc8grscoc.blob.core.windows.net/files/CountryRiskTemplate/CostOfDebtAndWACCInputs.pdf');
                            this.krollTemplateHyperlink(sheet, 'H42', 'https://vasdc8grscoc.blob.core.windows.net/files/CountryRiskTemplate/InternationalFisherEffectInputs.pdf');
                            this.krollTemplateHyperlink(sheet, 'H53', 'https://vasdc8grscoc.blob.core.windows.net/files/CountryRiskTemplate/CostOfEquityCalculations.pdf');
                            this.krollTemplateHyperlink(sheet, 'H74', 'https://vasdc8grscoc.blob.core.windows.net/files/CountryRiskTemplate/CostOfDebtCalculations.pdf');
                            this.krollTemplateHyperlink(sheet, 'H114', 'https://vasdc8grscoc.blob.core.windows.net/files/CountryRiskTemplate/WACCCalculations.pdf');
                            this.krollTemplateHyperlink(sheet, 'H143', 'https://vasdc8grscoc.blob.core.windows.net/files/CountryRiskTemplate/CostOfCapitalConclusions.pdf');

                            this.krollTemplateCustomDdn(sheet, 'B36', 'Investee Country Tax Rate (sourced from the Tax Foundation)')
                            this.krollTemplateCustomDdn(sheet, 'B49', 'Long-term Expected Inflation (sourced from IHS))')
                            this.krollTemplateCustomDdn(sheet, 'B46', 'Long-term Expected Inflation (sourced from IHS))')

                            this.krollTemplateValues(sheet, 'C36', '->');
                            this.krollTemplateFormulas(sheet, 'D36', '=IFERROR(IF(ISNUMBER(B36),B36,L40),"")')


                           this.krollTemplateValues(sheet, 'C30', '->');
                           this.krollTemplateValues(sheet, 'C46', '->');
                           this.krollTemplateValues(sheet, 'C49', '->');

                           this.krollTemplateFormulas(sheet, 'D46', '=IF(ISBLANK(B46),"",IF($J$3=$L$3,"",IF(ISNUMBER(B46),B46,M43)))')
                           this.krollTemplateFormulas(sheet, 'D47', '=IF(OR(ISNUMBER(D46),D46=""),"",KrollCountryRiskExport!F28)')
                           this.krollTemplateFormulas(sheet, 'D30', '=IF(ISBLANK(B30),"",IF(B30="Investor Country",A2,B$2))')

                           this.krollTemplateFormulas(sheet, 'D49', '=IF(ISBLANK(B49),"",IF($J$3=$L$3,"",IF(ISNUMBER(B49),B49,M44)))')
                           this.krollTemplateFormulas(sheet, 'D50', '=IF(OR(ISNUMBER(D49),D49=""),"",KrollCountryRiskExport!F28)')


                                this.krollTemplateValues(sheet, 'J2', 'Investor Currency');
                                this.krollTemplateValues(sheet, 'L2', 'Investee Currency');
                                this.krollTemplateFormulas(sheet, 'J3', '=IFERROR(INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(A2,KrollCountryRiskExport!$A$30:$A$222,0)),"")');
                                this.krollTemplateValues(sheet, 'K3', '=J3');
                                this.krollTemplateFormulas(sheet, 'L3', '=IFERROR(INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(B2,KrollCountryRiskExport!$A$30:$A$222,0)),"")');
                                this.krollTemplateValues(sheet, 'J5', 'CoE Input Currency');
                                this.krollTemplateValues(sheet, 'L5', 'CoD Input Currency');
                                this.krollTemplateFormulas(sheet, 'J6', '=IFERROR(INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(B10,KrollCountryRiskExport!$A$30:$A$222,0)),"")');
                                this.krollTemplateValues(sheet, 'K6', '=J6');
                                this.krollTemplateFormulas(sheet, 'L6', '=IFERROR(INDEX(KrollCountryRiskExport!$B$30:$B$222,MATCH(D30,KrollCountryRiskExport!$A$30:$A$222,0)),"")');

                                this.krollTemplateValues(sheet, 'J8', 'Risk-free Rates');
                                this.krollTemplateFormulas(sheet, 'J10', '=KrollCountryRiskExport!C12', true)
                                this.krollTemplateFormulas(sheet, 'K10', '=KrollCountryRiskExport!A12')
                                this.krollTemplateFormulas(sheet, 'L10', '=KrollCountryRiskExport!$A$2&" "&KrollCountryRiskExport!A12&": "&TEXT(KrollCountryRiskExport!C12,"0.00%")')
                                this.krollTemplateFormulas(sheet, 'M10', '=KrollCountryRiskExport!D12')

                                this.krollTemplateFormulas(sheet, 'J11', '=KrollCountryRiskExport!C13', true)
                                this.krollTemplateFormulas(sheet, 'K11', '=KrollCountryRiskExport!A13')
                                this.krollTemplateFormulas(sheet, 'L11', '=KrollCountryRiskExport!$A$2&" "&KrollCountryRiskExport!A13&": "&TEXT(KrollCountryRiskExport!C13,"0.00%")')
                                this.krollTemplateFormulas(sheet, 'M11', '=KrollCountryRiskExport!D13')


                                this.krollTemplateFormulas(sheet, 'J12', '=KrollCountryRiskExport!C14', true)
                                this.krollTemplateFormulas(sheet, 'K12', '=KrollCountryRiskExport!A14')
                                this.krollTemplateFormulas(sheet, 'L12', '=KrollCountryRiskExport!$A$2&" "&KrollCountryRiskExport!A14&": "&TEXT(KrollCountryRiskExport!C14,"0.00%")')
                                this.krollTemplateFormulas(sheet, 'M12', '=KrollCountryRiskExport!D14')

                                this.krollTemplateFormulas(sheet, 'J13', '=KrollCountryRiskExport!C15', true)
                                this.krollTemplateFormulas(sheet, 'K13', '=KrollCountryRiskExport!A15')
                                this.krollTemplateFormulas(sheet, 'L13', '=KrollCountryRiskExport!$A$2&" "&KrollCountryRiskExport!A15&": "&TEXT(KrollCountryRiskExport!C15,"0.00%")')
                                this.krollTemplateFormulas(sheet, 'M13', '=KrollCountryRiskExport!D15')

                                this.krollTemplateValues(sheet, 'J14', 'ERPs')
                                this.krollTemplateFormulas(sheet, 'J15', '=IF(ISBLANK(KrollCountryRiskExport!C6),"",KrollCountryRiskExport!C6)', true);
                                this.krollTemplateFormulas(sheet, 'K15', '=KrollCountryRiskExport!A6');
                                this.krollTemplateFormulas(sheet, 'L15', '=IF(J15="","",KrollCountryRiskExport!$A$2&" "&KrollCountryRiskExport!A6&": "&TEXT(KrollWACCTemplate!J15,"0.00%"))')
                                this.krollTemplateFormulas(sheet, 'M15', '=IF(J15="","",KrollCountryRiskExport!D6)');

                                this.krollTemplateFormulas(sheet, 'J16', '=IF(ISBLANK(KrollCountryRiskExport!C7),"",KrollCountryRiskExport!C7)', true);
                                this.krollTemplateFormulas(sheet, 'K16', '=KrollCountryRiskExport!A7');
                                this.krollTemplateFormulas(sheet, 'L16', '=IF(J16="","",KrollCountryRiskExport!$A$2&" "&KrollCountryRiskExport!A7&": "&TEXT(KrollWACCTemplate!J16,"0.00%"))')
                                this.krollTemplateFormulas(sheet, 'M16', '=IF(J16="","",KrollCountryRiskExport!D7)');

                                this.krollTemplateFormulas(sheet, 'J17', '=IF(ISBLANK(KrollCountryRiskExport!C8),"",KrollCountryRiskExport!C8)', true);
                                this.krollTemplateFormulas(sheet, 'K17', '=KrollCountryRiskExport!A8');
                                this.krollTemplateFormulas(sheet, 'L17', '=IF(J17="","",KrollCountryRiskExport!$A$2&" "&KrollCountryRiskExport!A8&": "&TEXT(KrollWACCTemplate!J17,"0.00%"))')
                                this.krollTemplateFormulas(sheet, 'M17', '=IF(J17="","",KrollCountryRiskExport!D8)');



                                this.krollTemplateValues(sheet, 'J20', 'Region');
                                this.krollTemplateValues(sheet, 'L21', 'RAW (OLS) - Levered');
                                this.krollTemplateValues(sheet, 'L22', 'Vasicek Adjusted - Levered');
                                this.krollTemplateValues(sheet, 'J26', 'Betas');
                                this.krollTemplateValues(sheet, 'L26', 'Lookup (don\'\t drag formulas)');
                                this.krollTemplateValues(sheet, 'M26', 'dataAsOf');

                                this.krollTemplateFormulas(sheet, 'J27', '=IF(ISBLANK(KrollCountryRiskExport!C19),"",KrollCountryRiskExport!C19)')
                                this.krollTemplateFormulas(sheet, 'K27', '=KrollCountryRiskExport!A19');
                                this.krollTemplateFormulas(sheet, 'L27', '=IF(J27="","",J27&" - "&KrollCountryRiskExport!A19)');
                                this.krollTemplateFormulas(sheet, 'M27', '=IF(ISBLANK(KrollCountryRiskExport!D19),"",KrollCountryRiskExport!D19)')

                                this.krollTemplateFormulas(sheet, 'J28', '=IF(ISBLANK(KrollCountryRiskExport!C20),"",KrollCountryRiskExport!C20)')
                                this.krollTemplateFormulas(sheet, 'K28', '=KrollCountryRiskExport!A20');
                                this.krollTemplateFormulas(sheet, 'L28', '=IF(J28="","",J28&" - "&KrollCountryRiskExport!A20)');
                                this.krollTemplateFormulas(sheet, 'M28', '=IF(ISBLANK(KrollCountryRiskExport!D20),"",KrollCountryRiskExport!D20)')

                                this.krollTemplateFormulas(sheet, 'J29', '=IF(ISBLANK(KrollCountryRiskExport!C21),"",KrollCountryRiskExport!C21)')
                                this.krollTemplateFormulas(sheet, 'K29', '=KrollCountryRiskExport!A21');
                                this.krollTemplateFormulas(sheet, 'L29', '=IF(J29="","",J29&" - "&KrollCountryRiskExport!A21)');
                                this.krollTemplateFormulas(sheet, 'M29', '=IF(ISBLANK(KrollCountryRiskExport!D21),"",KrollCountryRiskExport!D21)')

                                this.krollTemplateFormulas(sheet, 'J30', '=IF(ISBLANK(KrollCountryRiskExport!C22),"",KrollCountryRiskExport!C22)')
                                this.krollTemplateFormulas(sheet, 'K30', '=KrollCountryRiskExport!A22');
                                this.krollTemplateFormulas(sheet, 'L30', '=IF(J30="","",J30&" - "&KrollCountryRiskExport!A22)');
                                this.krollTemplateFormulas(sheet, 'M30', '=IF(ISBLANK(KrollCountryRiskExport!D22),"",KrollCountryRiskExport!D22)')

                                this.krollTemplateFormulas(sheet, 'J31', '=IF(ISBLANK(KrollCountryRiskExport!C23),"",KrollCountryRiskExport!C23)')
                                this.krollTemplateFormulas(sheet, 'K31', '=KrollCountryRiskExport!A23');
                                this.krollTemplateFormulas(sheet, 'L31', '=IF(J31="","",J31&" - "&KrollCountryRiskExport!A23)');
                                this.krollTemplateFormulas(sheet, 'M31', '=IF(ISBLANK(KrollCountryRiskExport!D23),"",KrollCountryRiskExport!D23)')

                                this.krollTemplateFormulas(sheet, 'J32', '=IF(ISBLANK(KrollCountryRiskExport!C24),"",KrollCountryRiskExport!C24)')
                                this.krollTemplateFormulas(sheet, 'K32', '=KrollCountryRiskExport!A24');
                                this.krollTemplateFormulas(sheet, 'L32', '=IF(J32="","",J32&" - "&KrollCountryRiskExport!A24)');
                                this.krollTemplateFormulas(sheet, 'M32', '=IF(ISBLANK(KrollCountryRiskExport!D24),"",KrollCountryRiskExport!D24)')

                                this.krollTemplateFormulas(sheet, 'J33', '=IF(ISBLANK(KrollCountryRiskExport!C24),"",KrollCountryRiskExport!C24)')
                                this.krollTemplateFormulas(sheet, 'K33', '=KrollCountryRiskExport!A24');
                                this.krollTemplateFormulas(sheet, 'L33', '=IF(J32="","",J32&" - "&KrollCountryRiskExport!A24)');
                                this.krollTemplateFormulas(sheet, 'M33', '=IF(ISBLANK(KrollCountryRiskExport!D24),"",KrollCountryRiskExport!D24)')

                                this.krollTemplateFormulas(sheet, 'J34', '=IF(ISBLANK(KrollCountryRiskExport!C25),"",KrollCountryRiskExport!C25)')
                                this.krollTemplateFormulas(sheet, 'K34', '=KrollCountryRiskExport!A25');
                                this.krollTemplateFormulas(sheet, 'L34', '=IF(J34="","",J34&" - "&KrollCountryRiskExport!A25)');
                                this.krollTemplateFormulas(sheet, 'M34', '=IF(ISBLANK(KrollCountryRiskExport!D25),"",KrollCountryRiskExport!D25)')

                                this.krollTemplateFormulas(sheet, 'J35', '=IF(ISBLANK(KrollCountryRiskExport!C26),"",KrollCountryRiskExport!C26)')
                                this.krollTemplateFormulas(sheet, 'K35', '=KrollCountryRiskExport!A26');
                                this.krollTemplateFormulas(sheet, 'L35', '=IF(J35="","",J35&" - "&KrollCountryRiskExport!A26)');
                                this.krollTemplateFormulas(sheet, 'M35', '=IF(ISBLANK(KrollCountryRiskExport!D26),"",KrollCountryRiskExport!D26)')

                                this.krollTemplateValues(sheet, 'J39', 'Tax Rate');
                                this.krollTemplateFormulas(sheet, 'J40','=INDEX(KrollCountryRiskExport!$G$30:$G$222,MATCH(B2,KrollCountryRiskExport!$A$30:$A$222,0))');
                                this.krollTemplateFormulas(sheet, 'L40', '=IF(J40="NULL","",TEXT(J40,"0.0%")&" - "&B2&" Tax Rate ("&LEFT(KrollCountryRiskExport!$G$29,4)&")")')

                                this.krollTemplateValues(sheet, 'J42', 'Inflation');
                                this.krollTemplateValues(sheet, 'M42', 'Dropdown');
                                this.krollTemplateFormulas(sheet, 'J43', '=B10');
                                this.krollTemplateFormulas(sheet, 'L43', '=ROUND(INDEX(KrollCountryRiskExport!$F$30:$F$222,MATCH(J43,KrollCountryRiskExport!$A$30:$A$222,0)),4)', true)
                                this.krollTemplateFormulas(sheet, 'M43', '=TEXT(L43,"0.00%")&": "&J43&" Expected Inflation ("&IF(MONTH(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))=12,YEAR(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))+1,YEAR(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11)))&"–"&IF(MONTH(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))=12,YEAR(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))+30,YEAR(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))+29)&")"');
                                this.krollTemplateFormulas(sheet, 'J44', '=IF(J43=A2,B2,A2)');
                                this.krollTemplateFormulas(sheet, 'L44', '=ROUND(INDEX(KrollCountryRiskExport!$F$30:$F$222,MATCH(J44,KrollCountryRiskExport!$A$30:$A$222,0)),4)', true);
                                this.krollTemplateFormulas(sheet, 'M44', '=TEXT(L44,"0.00%")&": "&J44&" Expected Inflation ("&IF(MONTH(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))=12,YEAR(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))+1,YEAR(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11)))&"–"&IF(MONTH(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))=12,YEAR(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))+30,YEAR(RIGHT(KrollCountryRiskExport!$F$28,LEN(KrollCountryRiskExport!$F$28)-11))+29)&")"');
                                const hideRows = sheet.getRange('J1:O1');
                                hideRows.columnHidden = true;
                                this.krollTemplateValuesFormat(sheet, ['B59','D59','F59','B63','D63','F63','B65','D65','B67','D67','F67',
                                                                     'B69','D69','F69','B71','D71','F71','B80','D80','F80','B82','D82','F82',
                                                                     'B85','D85','F85','B87','D87','F87','B89','D89','F89','B105','D105','F105',
                                                                     'B107','D107','F107','B109','D109','F109','B111','D111','F111','B123','D123',
                                                                     'F123','B125','D125','F125','B127','D127','F127','B129','D129','F129','B134',
                                                                     'D134','F134','B136','D136','F136','B138','D138','F138','B140','D140','F140',
                                                                     'B150','D150','F150','B153','B154','B157','D157','F157','B160','B161','B162',
                                                                     'B101','D101','F101','B103','D103','F103','B121','D121','F121','B132','D132','F132'])  
                                
                                var conditionalFormattingRange = sheet.getRange("A132:F140");
                                var conditionalFormatting = conditionalFormattingRange.conditionalFormats.add(
                                    Excel.ConditionalFormatType.custom
                                );
                                conditionalFormatting.custom.format.fill.color = "white";
                                conditionalFormatting.custom.format.font.color = "white";
                                conditionalFormatting.custom.rule.formula = "=$A$132=\"\""
                        }
    
    public async intlExportSheetChanges(sheet: Excel.Worksheet){
        this.investeeCountryCount = this.investeeCountryCount + 29;
                            sheet.getUsedRange().worksheet.showGridlines = false;
                            //  const investeeCountryCount = this.intlCOCSheetService.intlCOCInvestDetails ? this.intlCOCSheetService.intlCOCInvestDetails.length : 0;

                                     const erpTargetCell = sheet.getRange('F6');
                                     const erpLabelCell = sheet.getRange('G6');
                                     erpTargetCell.numberFormat = [["0.00##\%"]]
                                     erpTargetCell.columnHidden = true;


                                if(Array.isArray(this.erpDropdownLabels) && this.erpDropdownLabels.length > 0){
                            erpTargetCell.dataValidation.rule ={
                            list:{
                                inCellDropDown: true,
                                source: "=KrollCountryRiskExport!$A$6:$A$9"
                                }
                            };
                            this.errorDataValiationRule(erpTargetCell);
                            erpTargetCell.format.wrapText = true;
                            erpLabelCell.values = [[this.ddnlabel]]
                            erpLabelCell.format.columnWidth = 200
                                }
                            erpTargetCell.format.fill.color = '#FFFFE0';

                                     const rfRateTargetCell = sheet.getRange('F12');
                                     const rfLabelCell = sheet.getRange('G12');
                                     rfRateTargetCell.numberFormat = [["0.00%"]]

                                    //  const formula = "=D6<100"
                                 if(Array.isArray(this.rfRateDropdownLabels) && this.rfRateDropdownLabels.length > 0){
                            rfRateTargetCell.dataValidation.rule ={
                            list:{
                                inCellDropDown: true,
                                source: "=KrollCountryRiskExport!$A$12:$A$16"
                                }

                            };

                            const hideText = sheet.getRange('B1');
                            
                            hideText.columnHidden = true;
                            hideText.format.columnWidth = 1;


                            this.errorDataValiationRule(rfRateTargetCell);

                              rfRateTargetCell.format.wrapText = true;
                              rfLabelCell.values = [[this.ddnlabel]];
                              rfLabelCell.format.columnWidth = 200;
                                }
                              rfRateTargetCell.format.fill.color = '#FFFFE0'
                                     const betaTargetCell = sheet.getRange('F19');
                                     const betaLabelCell = sheet.getRange('G19');

                                if(Array.isArray(this.betaDropdownLabels) && this.betaDropdownLabels.length > 0){
                            betaTargetCell.dataValidation.rule ={
                            list:{
                                inCellDropDown: true,
                                source: "=KrollCountryRiskExport!$A$19:$A$26"
                                },
                            };
                            this.errorDataValiationRule(betaTargetCell);

                              betaTargetCell.format.wrapText = true;
                              betaLabelCell.values = [[this.ddnlabel]];
                              betaLabelCell.format.columnWidth = 200;
                                }
                              betaTargetCell.format.fill.color = '#FFFFE0';
                              const countryRow = sheet.getRange('A28:A29');
                              countryRow.format.rowHeight = 25;
                              countryRow.format.wrapText = true;
                            //   countryRowrapTextw.format.verticalAlignment = 'Bottom';
                            this.dateFormat(sheet, ['C2','D6','D7','D8','D9','D10','D11','D12','D13','D14',
                                            'D15','D16','D17','D19','D20','D21','D22','D23','D24','D25','D26','D27']);
                              this.exportSheetTextWrap(sheet, ['F29','G29','H29','I29','J29']);
                              this.exportSheetFormatLeft(sheet, [`C1:D$222`
                                                        ,`F29:J$222`])
                              const countryYieldText = sheet.getRange('H28');
                              countryYieldText.format.wrapText = true;
                              countryYieldText.format.font.italic = true;
                              countryYieldText.format.horizontalAlignment = 'Center'

                              const yeildborderLeft = sheet.getRange(`H29:H$222`);
                              yeildborderLeft.format.borders.getItem('EdgeLeft').style = 'Continuous';

                              const yeildborderRight = sheet.getRange(`J29:J$222`);
                              yeildborderRight.format.borders.getItem('EdgeRight').style = 'Continuous';


                              this.krollTemplateDottedBottomBorder(sheet, ['F6','F12','F19']);
                              this.krollTemplateContinuousBottomBorder(sheet, ['A5:D5','F5','A11:D11','F11',
                                                                       'A18:D18','F18','A28:J28','A29:J29',`A$222:J$222`]);
        const cocResourceCenterUrlCell = sheet.getRange('G13');
        let cocResourceCenterHyperLink = {
            textToDisplay: 'Click here for recommended ERP/risk-free rate guidance',
            screenTip: `${this.cocResourceCenterUrl} Click once to follow. Click and hold to select this cell`,
            address: this.cocResourceCenterUrl
        };
        cocResourceCenterUrlCell.format.font.color = 'blue';
        cocResourceCenterUrlCell.format.font.underline = 'Single';
        cocResourceCenterUrlCell.hyperlink = cocResourceCenterHyperLink;
        cocResourceCenterUrlCell.format.font.color = 'blue';
                            
                                                                   
    }

     public krollTemplateContinuousBottomBorder(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
         const borderRequired = sheet.getRange(range);
        borderRequired.format.borders.getItem('EdgeBottom').style = 'Continuous';
        })
    }
    public krollTemplateValuesFormat(sheet: Excel.Worksheet, ranges: string[]){
        ranges.forEach((range) => {
            const formattobeUpdated = sheet.getRange(range);
            formattobeUpdated.numberFormat = [['0.00%']];
        });
    }

    public async exportMultipleExcel(excelWorkbooks: Workbook[]): Promise<void> {
        for(const excelWorkbook of excelWorkbooks)
        for (const excelWorkSheet of excelWorkbook.sheets) {
            await this.removeAvailbleExcel(excelWorkSheet).then(async () => {
                await this.exportExcel(excelWorkSheet);
            });
        }
    }
    public async exportMultipleIntlExcel(excelWorkbooks: Workbook[]): Promise<void> {
        this.excelPosition = 0;
        for(const excelWorkbook of excelWorkbooks)
        for (const excelWorkSheet of excelWorkbook.sheets) {
            await this.updateAvailbleExcel(excelWorkSheet).then(async () => {
                await this.updateKrollRiskExportSheet(excelWorkSheet).then(async () => {
                    // await this.updateKrollTemplateFormulas(excelWorkSheet);
                    this.isExportTemplateEnable = true;
                    console.log('time out');
                });
            });
        }
    }
    public async exportMultipleIntlExcelTemplate(excelWorkbooks: Workbook[]): Promise<void> {
        this.excelPosition = 0;
        for(const excelWorkbook of excelWorkbooks)
        for (const excelWorkSheet of excelWorkbook.sheets) {
            await this.updateKrollExcelTemplateName(excelWorkSheet).then(async () => {
                await this.exportIntlCOCExcel(excelWorkSheet).then(async () => {
                    // await this.updateKrollTemplateFormulas(excelWorkSheet);
                    console.log('time out');
                });
            });
        }
    }
    public loadCOCExcelERPLabels(labels: string[]){
        this.erpDropdownLabels = labels;
    }
    public loadCOCExcelRfRateLabels(labels: string[]){
        this.rfRateDropdownLabels  = labels;
    }
    public loadCOCExcelBetaLabels(labels: string[]){
        this.betaDropdownLabels  = labels;
    }
    public getSheetColumnNames(): string[] {
        let text = "";
        for (let incrementor = 0; incrementor < 26; incrementor++) {
            text += String.fromCharCode(97 + incrementor) + ",";
        }
        for (let incrementor = 0; incrementor < 26; incrementor++) {
            for (let incrementor1 = 0; incrementor1 < 26; incrementor1++) {
                text +=
                    String.fromCharCode(97 + incrementor) +
                    String.fromCharCode(97 + incrementor1) +
                    ",";
            }
        }
        return text.trim().toUpperCase().split(",");
    }
    public async formatStyleSheet(
        range: Excel.Range,
        cell: WorkbookSheetRowCell
    ): Promise<void> {
        range.format.font.name = "Arial";
    
        if(cell.textAlign){
            range.format.horizontalAlignment = cell.textAlign as Excel.HorizontalAlignment
        }
        if(cell.background){
            range.format.fill.color = cell.background;
        }

        if (cell.fontSize) {
            range.format.font.size = cell.fontSize;
        }
        if (cell.color) {
            range.format.font.color = cell.color;
        }
        if (cell.bold) {
            range.format.font.bold = true;
        }

        //checking if all borders present used in industry benchmarking
        if(cell && cell.color && cell.borderBottom && cell.borderTop && cell.borderLeft && cell.borderRight) {
            if(cell.borderBottom?.color) {
                range.format.borders.getItem(Excel.BorderIndex.edgeBottom).color = cell.borderBottom.color;
                range.format.borders.getItem(Excel.BorderIndex.edgeBottom).weight = Excel.BorderWeight.thin;
            }
            
            if(cell.borderTop?.color) {
                range.format.borders.getItem(Excel.BorderIndex.edgeTop).color = cell.borderTop.color;
                range.format.borders.getItem(Excel.BorderIndex.edgeTop).weight = Excel.BorderWeight.thin;
            }
            
            if(cell.borderLeft?.color) {
                range.format.borders.getItem(Excel.BorderIndex.edgeLeft).color = cell.borderLeft.color;
                range.format.borders.getItem(Excel.BorderIndex.edgeLeft).weight = Excel.BorderWeight.thin;
            }
            
            if(cell.borderRight?.color) {
                range.format.borders.getItem(Excel.BorderIndex.edgeRight).color = cell.borderRight.color;
                range.format.borders.getItem(Excel.BorderIndex.edgeRight).weight = Excel.BorderWeight.thin;
            }
        } else {
            if (cell.borderBottom && cell.color) {
                range.format.borders.getItem(Excel.BorderIndex.edgeBottom).color =
                    cell.color;
                range.format.borders.getItem(Excel.BorderIndex.edgeBottom).weight =
                    Excel.BorderWeight.thick;
            }
            if (
                cell.borderBottom &&
                cell.borderBottom.size &&
                cell.borderBottom.color
            ) {
                range.format.borders.getItem(Excel.BorderIndex.edgeBottom).color =
                    cell.borderBottom.color;
                range.format.borders.getItem(Excel.BorderIndex.edgeBottom).weight =
                    Excel.BorderWeight.thick;
            }
            if (
                cell.borderBottom &&
                cell.borderBottom.size &&
                !cell.borderBottom.color
            ) {
                range.format.borders.getItem(Excel.BorderIndex.edgeBottom).color =
                    "#4D4D4F";
                range.format.borders.getItem(Excel.BorderIndex.edgeBottom).weight =
                    Excel.BorderWeight.thick;
            }
            if (cell.borderTop && cell.color) {
                range.format.borders.getItem(Excel.BorderIndex.edgeTop).color =
                    cell.color;
                range.format.borders.getItem(Excel.BorderIndex.edgeTop).weight =
                    Excel.BorderWeight.thick;
            }
        }
        
        if (cell.format && this.isValidNumber(cell.value) && typeof cell.value === "number") {
            // const formats = [["0", "0"], ["", "0.00"], ["0.00", "0.00"]];
            if (cell.format === "0" && cell.value < 1000) {
                const formats = [["0"]];
                range.numberFormat = formats;
            } else if (cell.format === "0.00" && cell.value < 1000) {
                const formats = [["0.00"]];
                range.numberFormat = formats;
            } else if (cell.format === "0,000" && cell.value > 1000) {
                const formats = [["0,000"]];
                range.numberFormat = formats;
            } else if (cell.format === "0.0" && cell.value < 1000) {
                const formats = [["0.0"]];
                range.numberFormat = formats;
            } else if (cell.format === "0.00%" && cell.value < 1000) {
                const formats = [["0.00%"]];
                range.numberFormat = formats;
            }
        }
    }

    //typescript evaluates number 0 as "false" so when we get value as 0
    //then we are explicitely checking instead of allowing typescript to evaluate it to "false"
    private isValidNumber(num: number | undefined | string | boolean | Date | null) {
        if(num !== null || num !== undefined || isNaN(num) === false) {
            return true
        }
        return false;
    }

    public showIndustries(): void {
        if (!(this.inputBuilder.intlInvestorCurrency && this.inputBuilder.intlInvestorCountry.value)
            || !(this.inputBuilder.intlCOCValuationDate && this.inputBuilder.intlCOCValuationDate.value)) {
            this.modalManager.open<void, IndustryListNoDetailsSelectedComponent>(IndustryListNoDetailsSelectedComponent, {
                mayDismiss: false
            });

            return;
        }

        const date = CommonDate.fromStruct(this.inputBuilder.intlValuationDate.value);




            this.tempIndustries = this.intlIndustryOptions;
            this.lstIndustries = this.intlIndustryOptions;


            var selectedIndustries: Gic[] = [];

            if (this.lstFinalSelectedIndustry?.length > 0) {
                this.lstIndustries.forEach(item => {
                    let objexisted = this.lstFinalSelectedIndustry.some(s => this.checkZoneCurencyCode(s, item));
                    if (objexisted) {
                        item.isSelected = true;
                    } else {
                        item.isSelected = false;
                    }

                });

            }

            if (this.lstSelectedIndustries?.length > 0 && this.lstSelectedIndustries?.length > this.lstFinalSelectedIndustry?.length) {
                selectedIndustries = this.lstSelectedIndustries.filter(d => !this.lstFinalSelectedIndustry.find(s => this.checkZoneCurencyCode(s, d)));
            }

            if (selectedIndustries?.length > 0) {
                this.lstIndustries.forEach(item => {
                    let isobjexisted = selectedIndustries.some(s => this.checkZoneCurencyCode(s, item));
                    if (isobjexisted) {
                        item.isSelected = true;
                    } else {
                        if (!this.lstFinalSelectedIndustry.some(k => this.checkZoneCurencyCode(k, item)))
                            item.isSelected = false;
                    }


                    if (this.lstSelectedIndustries.length == this.maximumNumberOfIndustries) {
                        item.isDisabled = true;
                    }

                })
            }


    }
    public checkCheckBoxvalue($event: any, industry: Gic) {
        const isChecked = $event.target.checked;
        if (isChecked && this.lstSelectedIndustries.length < this.maximumNumberOfIndustries) {
            let objIndex = this.lstIndustries.findIndex(s => this.checkZoneCurencyCode(s, industry));
            this.lstIndustries[objIndex].isSelected = true;
            this.lstSelectedIndustries.push(this.lstIndustries[objIndex])
        } else if (!isChecked) {
            let unselectedObj = this.lstIndustries.findIndex(s => this.checkZoneCurencyCode(s, industry));
            this.lstIndustries[unselectedObj].isSelected = false;
            let objSelectIndex = this.lstSelectedIndustries.findIndex(s => this.checkZoneCurencyCode(s, industry));
            this.lstSelectedIndustries.splice(objSelectIndex, 1);
        }

        if (this.lstSelectedIndustries.length === this.maximumNumberOfIndustries) {
            this.lstIndustries.filter(s => !this.lstSelectedIndustries.some(d => this.checkZoneCurencyCode(s, d))).map(s => s.isDisabled = true);
        } else {
            this.lstIndustries.map(d => d.isDisabled = false);
        }


    }

    public addIndustries() {
        this.lstFinalSelectedIndustry = [];
        this._check = 'button';
        this.lstFinalSelectedIndustry.push(...this.lstSelectedIndustries);
        this.getIntlBetas();
        this.isIndustryDisplay = false;
        this.isAddClicked = true;
        // localStorage.setItem("Industry", JSON.stringify(this.lstSelectedIndustries[0]));

    }




    public SelectIndustries(): boolean {
        if (this.inputBuilder.intlCOCValuationDate.value && this.intlIndustryOptions.length > 0) {
            this.isIndustryDisplay = true;
            return true;
        } else {
            return false;
        }

    }





    public removeIndustry(index: number, isModal: boolean) {
        if (this.lstSelectedIndustries.length === this.maximumNumberOfIndustries) {
            this.lstIndustries.map(x => x.isDisabled = false);
        }
        let objCod = this.lstSelectedIndustries[index];
        let objindex = this.lstIndustries.findIndex(s => this.checkZoneCurencyCode(s, objCod));
        if (objindex > -1) {
            this.lstIndustries[objindex].isSelected = false;
        }
        this.lstSelectedIndustries.splice(index, 1);

        if (!isModal) {
            this.lstFinalSelectedIndustry = []
            this.lstFinalSelectedIndustry.push(...this.lstSelectedIndustries);
        }
    }

    public onIndustrySearch(inputValue: any): void {

        if (inputValue !== '') {
            if (!isNaN(Number(inputValue))) {
                this.lstIndustries = this.tempIndustries.filter((s) => s.GicCode?.startsWith(inputValue, 0));
            } else {
                this.lstIndustries = this.tempIndustries.filter((s) => s.GicDescription?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1);

            }
        } else {
            this.lstIndustries = this.tempIndustries;
        }

    }

    public invalidClick() {
        return false;
    }

    public close(isClosed: string): void {
        if (this._check === '') {
            if (this.lstFinalSelectedIndustry?.length > 0) {
                this.lstIndustries.map(item => {
                    var objExisted = this.lstFinalSelectedIndustry.find(s => this.checkZoneCurencyCode(s, item));
                    if (objExisted) {
                        item.isSelected = true;
                    } else {
                        item.isSelected = false;
                    }
                });

                if (this.lstSelectedIndustries.length > this.lstFinalSelectedIndustry.length) {
                    this.lstIndustries.map(s => s.isDisabled = false);
                }
                this.lstSelectedIndustries = [];
                this.lstSelectedIndustries.push(...this.lstFinalSelectedIndustry);

            } else {
                this.lstSelectedIndustries.length = 0;
                this.lstIndustries.map(s => { s.isSelected = false; s.isDisabled = false })
            }

            if (this.lstSelectedIndustries.length > this.lstFinalSelectedIndustry.length) {
                this.lstIndustries.map(s => s.isDisabled = false);
            }
        }
        this._check = '';
        this.isIndustryDisplay = false;

    }

    public checkZoneCurencyCode(IndustryobjA: Gic, industryobjB: Gic): boolean {
        return (IndustryobjA.GicId == industryobjB.GicId);
    }
     public resetIntlIndustries() {
        this.inputBuilder.intlInvestorCountry.value = ''
        this.inputBuilder.intlInvestorCurrency.value = '';
        this.lstIndustries = [];
        this.lstIndustries.length = 0;
        this.lstSelectedIndustries = [];
        this.lstSelectedIndustries.length = 0;
        this.lstFinalSelectedIndustry = [];
        this.lstFinalSelectedIndustry.length = 0;
        this.listIntlRfClients = [];
        this.listIntlErpClients = [];
        this.listIntlBetas = [];
        this.isRefreshDataEnable = false;
        this.isIndustryNotAvailable = false;
    }
    public getErpClients(){
    this.listIntlRfClients = [];
    this.listIntlErpClients = [];
    this.listIntlBetas = [];
    const requestCountry = this.investorCountries.filter((x) => x.CountryName.toLowerCase() === this.inputBuilder.intlInvestorCountry.value.toLowerCase());
    const requestDate = ngbDateStructToString(this.inputBuilder.intlCOCValuationDate.value).split('T')[0];
    const countryId = requestCountry[0].CountryId;
    if(requestDate && countryId){
        this.spinner.begin();
        this.intlErpClients.read(countryId, requestDate).onceDefined((value: IntlData[]) =>{
        if(value.length > 0){
            this.listIntlErpClients = value;
        }
        this.getRfClients();

    });
    }

  }
  public getRfClients(){
    this.listIntlRfClients = [];
    const requestCountry = this.investorCountries.filter((x) => x.CountryName.toLowerCase() === this.inputBuilder.intlInvestorCountry.value.toLowerCase());
    const requestDate = ngbDateStructToString(this.inputBuilder.intlCOCValuationDate.value).split('T')[0];
    const countryId = requestCountry[0].CountryId;
    if(requestDate && countryId){
        this.spinner.begin();
        this.intlRfClients.read(countryId, requestDate).onceDefined((value: IntlData[]) =>{
        if(value.length > 0){
            this.listIntlRfClients = value;
        }
        this.spinner.end();

    });
    }
    // localStorage.setItem('IntlInvestorCountry',JSON.stringify(this.inputBuilder.intlInvestorCountry));
    // localStorage.setItem('IntlInvestorCurrency',JSON.stringify(this.inputBuilder.intlInvestorCurrency));

    this.getIntlIndustries(this.inputBuilder.intlInvestorCountry.value);

  }
  public getIntlBetas(){
    this.listIntlBetas = [];
    const requestCountry = this.investorCountries.filter((x) => x.CountryName.toLowerCase() === this.inputBuilder.intlInvestorCountry.value.toLowerCase());
    const requestDate = ngbDateStructToString(this.inputBuilder.intlCOCValuationDate.value).split('T')[0];
    // const countryId = requestCountry[0].CountryId;
    const currencyId = requestCountry[0].Currency.CurrencyId;
    const gicId = this.lstIndustries.length > 0? this.lstIndustries[0].GicId : null;
    if(requestDate && currencyId && gicId){
        this.spinner.begin();
        this.intlBetas.intlRead(gicId, currencyId, requestDate).onceDefined((value: IntlData[]) =>{
            if(value.length > 0){
            this.listIntlBetas = value;
        }
        this.spinner.end();
        });
    }
  }
   public getInvestors(){
    this.spinner.begin();
        this.intlCountries.investors().onceDefined((value: Country[]) =>{
            if(value.length > 0){
                this.investorCountries = value;
            }
             const country = this.inputBuilder.intlInvestorCountry;
        if(this.investorCountries.length > 0){
            this.spinner.begin();
        this.investorCountries.forEach((c) => {
            this.investorCountriesComboOptions.push({
                 name: c.CountryName,
                 value: c.CountryName,
                 source: country.source,
                 dataPoint: '',
                 dataAsOf: ''
            });
        });
        this.spinner.end();

        }

        this.inputBuilder.intlInvestorCountry.config = {
                    readonly: true,
                    required: true,
                    help: help.InternationalInvestorCountry
        }
        });

        this.spinner.end();
  }
 public getIntlIndustries(selectedInvestorCountry: any){
        this.isIndustryNotAvailable = false;
        if(selectedInvestorCountry && selectedInvestorCountry){
                const requestCountry = this.investorCountries.filter((x) => x.CountryName.toLowerCase() === selectedInvestorCountry.toLowerCase());
                const requestDate = ngbDateStructToString(this.inputBuilder.intlCOCValuationDate.value).split('T')[0];
                const requestCountryId = requestCountry[0].CountryId;
            const requestCurrencyId = requestCountry[0].Currency.CurrencyId;
            const intlIndustry = this.inputBuilder.intlIndustry;
        this.spinner.begin();

            this.intlIndustries.read(requestCountryId, requestCurrencyId, requestDate).
            onceDefined((value: Gic[]) =>{
                this.intlIndustryOptions = [];
                this.intlIndustryComboOptions = [];
                if(value.length > 0){
                    this.intlIndustryOptions = value;
                    this.intlIndustryOptions.forEach((industry) => {
                    this.intlIndustryComboOptions.push({
                        name: 'GICS' + ' ' + industry.GicCode + '-' + industry.GicDescription,
                        value: 'GICS' + ' ' + industry.GicCode + '-' + industry.GicDescription,
                        source: intlIndustry.source,
                        dataPoint: '',
                        dataAsOf: ''
                        });
                    });


                }
                this.inputBuilder.intlIndustry.config = {
                    readonly: true,
                    required: false,
                    help: help.InternationalIndustry,
                    disabled: this.intlIndustryOptions.length === 0? true : false

                }
                if(this.inputBuilder.intlInvestorCurrency.value && this.intlIndustryOptions.length === 0){
                    this.isIndustryNotAvailable = true;
                }
                  this.tempIndustries = this.intlIndustryOptions;
            this.lstIndustries = this.intlIndustryOptions;
            this.isRefreshDataEnable = true;

                // this.SelectIndustries();

            this.spinner.end();

            });
        }
  }


  public reflectInvestor(investorCountry: any){
    this.investorCurrencyComboOptions = [];
    this.inputBuilder.intlInvestorCurrency.value = "";
    this.inputBuilder.intlIndustry.value = "";
    this.lstIndustries = [];
    this.lstSelectedIndustries = [];
    this.lstFinalSelectedIndustry = [];
    if(investorCountry.value){
     const findInvestorCountry = this.investorCountries.filter((x) => x.CountryName.toLowerCase() === investorCountry.value.toLowerCase());
     if(findInvestorCountry.length > 0){
        const investorCurrency = this.inputBuilder.intlInvestorCurrency;
        findInvestorCountry.forEach((currency) =>{
             this.investorCurrencyComboOptions.push({
                 name: currency.Currency.CurrencyAcronym,
                 value: currency.Currency.CurrencyAcronym,
                 source: investorCurrency.source,
                 dataPoint: '',
                 dataAsOf: ''
            });
        });
            this.inputBuilder.intlInvestorCurrency.config = {
                    readonly: true,
                    required: false,
                    help: help.InternationalInvestorCurrency,
                    disabled: this.investorCurrencyComboOptions.length === 1? true : false

        }
        this.inputBuilder.intlInvestorCurrency.value = this.investorCurrencyComboOptions[0].value;
        this.intlInvestorCurrencyExists = this.inputBuilder.intlInvestorCurrency.value;
        this.intlInvestorCountryEXists = this.inputBuilder.intlInvestorCountry.value;

        if(this.inputBuilder.intlInvestorCurrency.config.disabled === true){
            this.investorCurrencyComboOptions = [];
        }
        this.getErpClients();

     }
    }
  }  
  
  public updateValuationDateIntl(valuationDate: DateStruct){
    this.resetIntlIndustries();
    // if(this.inputBuilder.intlInvestorCountry && this.inputBuilder.intlInvestorCountry.value 
    //   && this.inputBuilder.intlInvestorCurrency && this.inputBuilder.intlInvestorCurrency.value){
    //     this.getErpClients();
    //   }

    // if(this.preserveIntlValuationDate && valuationDate){
    //     const newValuationDate = moment(valuationDate).format('MM/DD/YY');
    //     const preservedValuationDate = moment(this.preserveIntlValuationDate).format('MM/DD/YY');
    //     if(newValuationDate !== preservedValuationDate){
    //         this.preserveIntlValuationDate = valuationDate;
    //     }
    // }else if(valuationDate){
    //         this.preserveIntlValuationDate = valuationDate;
    // }

  }
}
